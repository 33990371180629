import { useSDK } from '@tma.js/sdk-react';
import { useMemo } from 'react';
import { LoadingInit } from '../../atoms/Loading';

const TmaLoader = ({ children }) => {
  const { didInit, components, error } = useSDK();

  const errorMessage = useMemo(() => {
    if (!error) {
      return null;
    }

    return error instanceof Error ? error.message : 'Unknown error';
  }, [error]);

  // There were no calls of SDK's init function. It means, we did not
  // even try to do it.
  if (!didInit) {
    return <LoadingInit />;
    // <div>SDK init function is not yet called.</div>;
  }

  // Error occurred during SDK init.
  if (error !== null) {
    return (
      <>
        <p>
          SDK was unable to initialize. Probably, current application is being used not in Telegram Web Apps
          environment.
        </p>
        <blockquote>
          <p>{errorMessage}</p>
        </blockquote>
      </>
    );
  }

  // If components is null, it means, SDK is not ready at the
  // moment and currently initializing. Usually, it takes like
  // several milliseconds or something like that, but we should
  // have this check.
  if (components === null) {
    return <LoadingInit />;
  }

  // Safely render application.
  return <>{children}</>;
};

export default TmaLoader;
