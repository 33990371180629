import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import appRoutes from '../../../core/routes/routes';
import userAppStore from '../../../stores/useAppStore.store';
import NavigationComponent from '../../atoms/NavigationComponent';
import { useLocation } from 'react-router-dom';

const NavigationBar = () => {
  const { modalApp } = userAppStore();

  const location = useLocation();

  const notOnRoutes = [appRoutes.PLAY];

  if (notOnRoutes.includes(location.pathname) || modalApp) {
    return null;
  }

  return (
    <React.Fragment>
      <div className={`fixed bottom-0 w-full h-12 z-50 flex bg-body-color-secondary items-center`}>
        <div
          className={`flex-1 text-center ${
            location.pathname === appRoutes.HOME ? 'text-navbar-active-color-primary' : 'text-text-color-secondary'
          }`}
        >
          <NavigationComponent to={appRoutes.HOME}>
            <HomeIcon />
            <div className="text-xxxs">HOME</div>
          </NavigationComponent>
        </div>
        <div
          className={`flex-1 text-center ${
            location.pathname === appRoutes.WALLET ? 'text-navbar-active-color-primary' : 'text-text-color-secondary'
          }`}
        >
          <NavigationComponent to={appRoutes.WALLET}>
            <AccountBalanceWalletIcon />
            <div className="text-xxxs">WALLET</div>
          </NavigationComponent>
        </div>
        <div
          className={`flex-1 text-center ${
            location.pathname === appRoutes.REFERRAL ? 'text-navbar-active-color-primary' : 'text-text-color-secondary'
          }`}
        >
          <NavigationComponent to={appRoutes.REFERRAL}>
            <GroupAddIcon />
            <div className="text-xxxs">REFERRAL</div>
          </NavigationComponent>
        </div>
        <div
          className={`flex-1 text-center ${
            location.pathname === appRoutes.ACCOUNT ? 'text-navbar-active-color-primary' : 'text-text-color-secondary'
          }`}
        >
          <NavigationComponent to={appRoutes.ACCOUNT}>
            <AccountCircleIcon />
            <div className="text-xxxs">PROFILE</div>
          </NavigationComponent>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NavigationBar;
