/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import appRoutes from '../../../core/routes/routes';
import WalletIcon from './components/WalletIcon';
import ProfileIcon from './components/ProfileIcon';

const Header = () => {
  const location = useLocation();

  const notOnRoutes = [appRoutes.PLAY];

  if (notOnRoutes.includes(location.pathname)) {
    return null;
  }
  return (
    <React.Fragment>
      <header className="containerContent flex items-center justify-between w-full h-16 bg-body-color-secondary">
        <NavLink to={appRoutes.HOME}>
          <div className="left-0 w-full h-10 aspect-3/1">
            <img src="/images/logo/diceplay-logo.png" className="object-cover w-full h-full" />
          </div>
        </NavLink>

        <div className="flex space-x-2">
          <div>
            <WalletIcon />
          </div>
          <div>
            {/* <NavLink to={appRoutes.ACCOUNT}> */}
            <ProfileIcon />
            {/* </NavLink> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};
export default Header;
