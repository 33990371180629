/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useClosingBehaviour, useLaunchParams } from '@tma.js/sdk-react';

import useAuth from './useAuth.hook';
import useAuthStore from '../stores/useAuthStore.store';

function useTelegram() {
  const { token, isAuthenticated, logout } = useAuthStore();
  const { loading, error, loginUser } = useAuth();
  const [telegramAuthStatus, setTelegramAuthStatus] = useState(false);

  // const initData = useInitData();
  const launchParams = useLaunchParams();
  const close = useClosingBehaviour();
  // const webApp = useWebApp();

  // console.log('webApp L: ', webApp);
  // console.log('launchParams L: ', launchParams);

  close.enableConfirmation();

  // useEffect(() => {
  //   console.log('close.isConfirmationNeeded : ', close.isConfirmationNeeded);
  // }, [close.isConfirmationNeeded]);

  useEffect(() => {
    logout();
  }, []);

  useEffect(() => {
    if (!token && !isAuthenticated) {
      // const userObject = {
      //   id: initData.user.id,
      //   first_name: initData.user.firstName,
      //   last_name: initData.user.lastName,
      //   username: initData.user.username,
      //   language_code: initData.user.languageCode,
      //   allows_write_to_pm: true, // Manually added this key as it was not present in the sample initData
      // };

      // const query = [
      //   `user=${encodeURIComponent(JSON.stringify(userObject))}`,
      //   `chat_instance=${initData.chatInstance}`,
      //   `chat_type=${initData.chatType}`,
      //   `auth_date=${Math.floor(new Date(initData.authDate).getTime() / 1000)}`,
      //   `hash=${initData.hash}`,
      // ];

      // Join the query array into a single string
      // const queryString = query.join('&');
      // console.log('queryString : ', queryString);
      // const hash = window.location.hash.slice(1);
      // const parsedParams = new URLSearchParams(hash);
      // console.log('parseParams : ', parsedParams);
      // const tgWebApp = parsedParams.get('tgWebAppData');
      // console.log('tgWebApp : ', tgWebApp);
      // setParams(parsedParams);
      loginUser(launchParams.initDataRaw);
      setTelegramAuthStatus(true);
    }
  }, [token, isAuthenticated]);

  return {
    telegramAuthStatus,
    loading,
    error,
  };
}

export default useTelegram;
