import React, { useCallback, useRef } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchIcon from '@mui/icons-material/Search';

const SearchComponent = ({ value, onChange, onSearch, readOnly, placeholder = 'Search' }) => {
  const inputRef = useRef(null);

  const searchHandler = (e) => {
    e.preventDefault(); // Prevent the form's default submit action
    if (onSearch) onSearch();
  };

  const clearInputHandler = useCallback(() => {
    onChange('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if (onSearch) onSearch();
  }, [onChange, onSearch]);

  const onChangeHandler = (e) => {
    onChange(e.target.value);
  };

  return (
    <React.Fragment>
      <form
        onSubmit={searchHandler}
        className="flex col-span-12 w-full h-full font-semibold bg-body-color-secondary rounded-xl"
      >
        <div className="flex w-full items-center relative px-2">
          <input
            ref={inputRef}
            type="text"
            value={value}
            onChange={onChangeHandler}
            className="w-full h-full text-base font-semibold outline-none pr-2 pl-1 bg-inherit placeholder:font-light placeholder:pl-1"
            placeholder={placeholder}
            readOnly={readOnly}
            aria-label="Search Input"
          />
          <div className="right-0 flex items-center space-x-2">
            {value && (
              <CloseOutlinedIcon
                className="text-text-color-primary cursor-pointer"
                onClick={clearInputHandler}
                aria-label="Clear Search"
              />
            )}

            <button type="submit" aria-label="Search">
              <SearchIcon className="text-text-color-primary cursor-pointer" />
            </button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default SearchComponent;
