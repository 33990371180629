import React from 'react';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../../core/routes/routes';
import userAppStore from '../../../stores/useAppStore.store';

const CardGame = ({ game }) => {
  let darkVibrant = game?.prominentColor?.DarkVibrant;

  const { setCloseModalApp } = userAppStore();
  const navigate = useNavigate();
  // const [showOptionsMobile, setShowOptionsMobile] = useState(false);

  // &demo=${game?.demoGameAvailable}

  const onClick = (demo) => {
    if (demo) {
      navigate(`${appRoutes.PLAY}?gpId=${game?.gpId}&gpGameId=${game?.gpGameId}&demo=${true}`);
      setCloseModalApp();
    } else {
      navigate(`${appRoutes.PLAY}?gpId=${game?.gpId}&gpGameId=${game?.gpGameId}&demo=${false}`);
      setCloseModalApp();
    }
  };

  // const toggleOptionsMobile = () => {
  //   setShowOptionsMobile(!showOptionsMobile);
  // };

  return (
    <React.Fragment>
      <div
        className="group relative rounded-xl aspect-4/5 overflow-hidden hover:bg-opacity-80 transition-all duration-300 ease-in-out cursor-pointer"
        style={{ backgroundColor: darkVibrant }}
        onMouseEnter={(e) => e.stopPropagation()}
        // onTouchMove={(e) => e.stopPropagation()}
        // onTouchStart={(e) => e.stopPropagation()}
        // onPointerDown={() => {
        //   if (!game?.demoGameAvailable) {
        //     onClick(false);
        //   }
        // }}
        onClick={() => {
          // if (!game?.demoGameAvailable) {
          onClick(false);
          // }
        }}
      >
        <img
          src={game?.s3ImageUrl?.square200x200 ? game?.s3ImageUrl?.square200x200 : '/images/game/dice-image.png'}
          alt="Description"
          className="w-full h-full object-cover"
          loading="lazy"
        />
        <div className="absolute w-full bottom-0 aspect-3/2">
          <div
            className="bg-gradient-to-t from-red-500 h-full text-center flex flex-col justify-end p-3 space-y-2"
            style={{
              backgroundImage: `linear-gradient(to top, ${darkVibrant} 60%, transparent)`,
            }}
          >
            <div className="text-sm leading-none text-text-color-primary font-semibold">{game?.gameName}</div>
            <div className="text-xxs leading-none text-text-color-primary">{game?.gpName}</div>
          </div>
        </div>

        {/* {game?.demoGameAvailable && (
          <React.Fragment>
            <div className="absolute top-0 left-0 w-full h-1/2 flex items-center justify-center transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100 bg-body-color-secondary hover:bg-navbar-active-color-primary">
              <button
                className="w-full h-full text-text-color-primary"
                onClick={() => {
                  onClick(false);
                }}
              >
                Realplay
              </button>
            </div>

            <div className="absolute bottom-0 left-0 w-full h-1/2 flex items-center justify-center transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100 bg-body-color-secondary hover:bg-navbar-active-color-primary">
              <button
                className="w-full h-full text-text-color-primary"
                onClick={() => {
                  onClick(true);
                }}
              >
                Funplay
              </button>
            </div>
          </React.Fragment>
        )} */}
      </div>
    </React.Fragment>
  );
};

export default CardGame;
