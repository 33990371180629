const appRoutes = {
  ACCOUNT: '/account',
  GAME: '/game',
  GAME_CATEGORY: '/game-category',
  GAME_PROVIDER: 'game-provider',
  HISTORY: '/history',
  HOME: '/',
  REFERRAL: '/referral',
  REWARDS: '/rewards',
  PLAY: '/play',
  WALLET: '/wallet',
  UNKNOWN: '*',
};

export default appRoutes;
