/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import NavigationTab from '../../organisms/NavigationTab';
import useAppTabs from '../../../hooks/useAppTabs.hook';
import { useSearchParams } from 'react-router-dom';
import HistoryDepoWd from '../../molecules/HistoryDepoWd';
import HistoryPlay from '../../molecules/HistoryPlay';
import HistorySwap from '../../molecules/HistorySwap';

const HistoryPage = () => {
  const { appTabs } = useAppTabs();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    const existQuery = urlSearchParams.get(appTabs.HISTORY.QUERY_NAME);
    if (!existQuery) {
      setUrlSearchParams({ [appTabs.HISTORY.QUERY_NAME]: appTabs.HISTORY.DEFAULT_QUERY_URL });
    }
  }, []);

  useEffect(() => {
    setSelectedTab(urlSearchParams.get(appTabs.HISTORY.QUERY_NAME));
  }, [urlSearchParams]);

  return (
    <React.Fragment>
      <div className="">
        <div className="pb-2">{appTabs.HISTORY.TITLE}</div>
        <div className="pb-2">
          <NavigationTab tabs={appTabs.HISTORY.ROUTES} queryName={appTabs.HISTORY.QUERY_NAME} />
        </div>
        {selectedTab === appTabs.HISTORY.ROUTES.DEPOWD.QUERY_URL && <HistoryDepoWd />}
        {selectedTab === appTabs.HISTORY.ROUTES.PLAY.QUERY_URL && <HistoryPlay />}
        {selectedTab === appTabs.HISTORY.ROUTES.SWAP.QUERY_URL && <HistorySwap />}
        <div></div>
      </div>
    </React.Fragment>
  );
};

export default HistoryPage;
