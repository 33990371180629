/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import useAuthStore from '../../../stores/useAuthStore.store';
import useApiRequest from '../../../hooks/useApiRequest.hook';
import CurrencyInputComponent from '../../atoms/CurrencyInputComponent';
import InputComponent from '../../atoms/InputComponent';
import ButtonComponent from '../../atoms/ButtonComponent';
import useNotification from '../../../hooks/useNotification.hook';
import WalletDropDown from '../../organisms/Header/components/WalletDropDown';

const WalletWithdrawCrypto = () => {
  const { data: dataFee, loading: loadingFee, /* error: errorFee, */ post: postFee } = useApiRequest('/fee');
  const {
    data: dataMinWd,
    loading: loadingMinWd,
    /*error: errorMinWd, */ post: postMinWd,
  } = useApiRequest('/depowdCrypto');
  const { data: dataWd, loading: loadingWd, error: errorWd, post: postWd } = useApiRequest('/depowdCrypto');
  const { notifySuccess, notifyError } = useNotification();

  const { token } = useAuthStore();

  const [selectedWallet, setSelectedWallet] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState(null);

  const [withdrawAddress, setWithdrawAddress] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('0');
  const [minWithdraw, setMinWithdraw] = useState(null);
  const [feeWithdraw, setFeeWithdraw] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedWallet) {
      const network =
        selectedWallet?.networkList && selectedWallet?.networkList.length ? selectedWallet?.networkList?.[0] : {};

      setError(null);
      setSelectedNetwork(network);
      getMinWithdraw(selectedWallet?.currency);
      getFeeWithdraw(network?.network, selectedWallet?.currency, withdrawAmount);
    }
  }, [selectedWallet]);

  useEffect(() => {
    if (dataMinWd) {
      setMinWithdraw(dataMinWd?.data ?? {});
    }
  }, [dataMinWd]);

  useEffect(() => {
    if (dataFee) {
      setFeeWithdraw(dataFee?.data ?? {});
    }
  }, [dataFee]);

  useEffect(() => {
    console.log('dataWd : ', dataWd);
    if (dataWd) {
      if (dataWd?.error === 0) {
        setWithdrawAddress('');
        setWithdrawAmount('0');
        setFeeWithdraw(null);
        notifySuccess('success');
      }
    }
  }, [dataWd]);

  useEffect(() => {
    if (errorWd) {
      notifyError(errorWd);
    }
  }, [errorWd]);

  const withdrawCrypto = () => {
    const amount = Number(withdrawAmount);

    if (!selectedWallet?.currency || selectedWallet?.currency === '') {
      return setError('Please select the currency');
    }
    if (!selectedNetwork?.network || selectedNetwork?.network === '') {
      return setError('Please select the network');
    }
    if (!withdrawAddress || withdrawAddress === '') {
      return setError('Please input the address');
    }
    if (amount <= 0) {
      return setError('Amount must more than 0');
    }

    // if (amount > selectedWallet?.balance) {
    //   return notifyError()
    // }

    if (
      (Number(minWithdraw?.minWithdraw) !== 0 && amount < Number(minWithdraw?.minWithdraw)) ||
      (Number(minWithdraw?.maxWithdraw) !== 0 && amount > Number(minWithdraw?.maxWithdraw))
    ) {
      return setError(
        `withdrawal amount must be between or equal to ${minWithdraw?.minWithdraw} ${selectedWallet?.currency} and ${
          minWithdraw?.maxWithdraw === 0 ? 'unlimited' : minWithdraw?.maxWithdraw
        } ${selectedWallet?.currency}`
      );
    }

    postWd({
      options: {
        headers: {
          Authorization: token,
        },
      },
      data: {
        type: 'withdraw',
        currency: selectedWallet?.currency,
        network: selectedNetwork?.network,
        toAddress: withdrawAddress,
        amount: amount,
      },
    });
    setError(null);
  };

  const getMinWithdraw = (currency) => {
    postMinWd({
      options: {
        headers: {
          Authorization: token,
        },
      },
      data: {
        type: 'minimalWithdraw',
        currency: currency,
      },
    });
  };

  const getFeeWithdraw = (network, currency, amount) => {
    if (network && currency && amount > 0) {
      postFee({
        options: {
          headers: {
            Authorization: token,
          },
        },
        data: {
          type: 'withdrawCryptoFee',
          network: network,
          currency: currency,
          amount: amount,
        },
      });
    }
  };

  const handleSelectedNetwork = (network) => {
    if (network) {
      setError(null);
      setSelectedNetwork(network);
    }
  };

  const onChangeAmount = (value) => {
    const amount = Number(value);
    setWithdrawAmount(value);
    setTimeout(() => {
      getFeeWithdraw(selectedNetwork?.network, selectedWallet?.currency, amount);
    }, 500);
  };

  return (
    <React.Fragment>
      <div className="space-y-2 text-text-color-secondary text-sm">
        <WalletDropDown selectedWallet={selectedWallet} setSelectedWallet={setSelectedWallet} currencyType="crypto" />

        {selectedWallet?.networkList && selectedWallet?.networkList.length ? (
          <React.Fragment>
            <div className="flex h-8 overflow-auto text-center items-center">
              {selectedWallet.networkList.map((network, index) => (
                <React.Fragment key={index}>
                  <div
                    className={`
                      flex bg-body-color-secondary rounded-xl px-4 h-full text-center items-center
                      ${
                        selectedNetwork?.network === network?.network
                          ? 'bg-navbar-active-color-primary text-text-color-primary'
                          : 'bg-body-color-secondary'
                      }
                    `}
                    onClick={() => {
                      handleSelectedNetwork(network);
                    }}
                  >
                    <span>{network?.network}</span>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </React.Fragment>
        ) : null}
        <InputComponent
          id="addressInput"
          label="Withdraw To Address"
          // notes="Please make sure that the address is correct."
          placeholder="0x023de23..."
          onChange={setWithdrawAddress}
          value={withdrawAddress}
        />
        <CurrencyInputComponent
          id="amountInput"
          label="Withdraw Amount"
          value={withdrawAmount}
          onChange={onChangeAmount}
        />
        <div className="block text-xs space-y-2 leading-none">
          <p className="flex justify-between pb-2">
            <span>
              min {minWithdraw?.minWithdraw} {minWithdraw?.currency}
            </span>
            <span>
              max {minWithdraw?.maxWithdraw === 0 ? 'unlimited' : minWithdraw?.maxWithdraw} {minWithdraw?.currency}
            </span>
          </p>
          {/* <p className="text-base leading-none">
            You will receive <span>{feeWithdraw?.receivedAmount ?? 0}</span> <span>{minWithdraw?.currency}</span>
          </p> */}
          <p className="flex justify-between leading-none">
            <span className="flex items-end">
              Fee {feeWithdraw?.fee ?? 0}&nbsp;
              <span>{minWithdraw?.currency}</span>
            </span>
            <span className="text-sm text-text-color-primary">
              You will receive <span>{feeWithdraw?.receivedAmount ?? 0}</span> <span>{minWithdraw?.currency}</span>
            </span>
          </p>
        </div>
        {error && <p className="text-red-600">{error}</p>}
        <ButtonComponent
          loading={loadingFee || loadingMinWd || loadingWd}
          onClick={withdrawCrypto}
          buttonName="Withdraw"
        />
        <p className="text-center text-red-600 text-xs">* Please make sure that the address is correct.</p>
      </div>
    </React.Fragment>
  );
};

export default WalletWithdrawCrypto;
