import React from 'react';

const NotFoundPage = () => {
  return (
    <React.Fragment>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">Page Not Found</div>
    </React.Fragment>
  );
};

export default NotFoundPage;
