import React from 'react';
import useAuthStore from '../../../../../stores/useAuthStore.store';
import WalletProfileModal from '../WalletProfileModal';
import walletUtils from '../../../../../utils/wallet.utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useWalletIconStore from '../../stores/useWalletIconStore.store';

const WalletIcon = () => {
  const { activeWalletBalance } = useAuthStore((state) => ({
    activeWalletBalance: state.activeWalletBalance,
  }));
  const { setOpenWalletModal } = useWalletIconStore((state) => ({
    setOpenWalletModal: state.setOpenWalletModal,
  }));

  // const funcOpenWalletModal = () => {
  //   setOpenWalletModal(!openWalletModal);
  // };

  // const funcCloseWalletModal = () => {
  //   setOpenWalletModal(!openWalletModal);
  // };

  return (
    <React.Fragment>
      <div
        className="relative flex items-center text-sm justify-between space-x-2 bg-body-color-tertiary rounded-full min-w-[7rem] max-w-[10rem] h-10 px-2 cursor-pointer"
        onClick={setOpenWalletModal}
      >
        <div className="flex rounded-full h-7 w-7 aspect-square">
          <img src={activeWalletBalance?.currencyLogo} alt="" className="w-full h-full" loading="lazy" />
        </div>
        <div className="flex w-full items-center max-w-[7rem]">
          <p className="flex w-full overflow-hidden truncate">
            {walletUtils.formatCurrency(activeWalletBalance?.totalBalance ?? 0)}
          </p>
          <KeyboardArrowDownIcon className="aspect-square leading-none" />
        </div>
      </div>
      <WalletProfileModal />
    </React.Fragment>
  );
};
export default WalletIcon;
