/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react';

const DropDown = ({ mainMenu, dropdownMenu, dropdownMenuClassName }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onCloseHandler = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <div className="relative">
        <div
          className="flex items-center justify-between w-full h-8 rounded-xl bg-body-color-secondary pl-4 pr-2 cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          {mainMenu ? <React.Fragment>{mainMenu}</React.Fragment> : <React.Fragment>Main Menu</React.Fragment>}

          {isOpen ? <ArrowDropDownIcon /> : <ArrowLeftIcon />}
        </div>

        {isOpen && (
          <div
            className="absolute w-full z-20 rounded-xl shadow-lg bg-body-color-secondary overflow-auto max-h-64"
            onClick={onCloseHandler}
          >
            {dropdownMenu}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default DropDown;
