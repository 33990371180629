import React from 'react';
import { LoadingRing } from '../Loading';

const ButtonComponent = ({ buttonName = 'Button', onClick, loading, disabled }) => {
  const onClickHandler = () => {
    if (!loading && onClick) {
      onClick();
    }
  };

  return (
    <button
      className={`
        flex items-center justify-center w-full h-8 border-button-color-primary bg-button-color-primary 
        text-button-text-primary p-4 rounded-xl cursor-pointer font-medium
        ${loading ? 'opacity-70' : ''}
      `}
      onClick={onClickHandler}
      disabled={disabled || loading}
    >
      {loading ? (
        <div className="w-6 h-6">
          <LoadingRing />
        </div>
      ) : (
        buttonName
      )}
    </button>
  );
};

export default ButtonComponent;
