import { useTranslation } from 'react-i18next';

function useAppTabs() {
  const { t } = useTranslation();
  const appTabs = {
    WALLET: {
      TITLE: 'Wallet',
      QUERY_NAME: 'walletTab',
      DEFAULT_QUERY_URL: 'deposit',
      ROUTES: {
        DEPOSIT: {
          TITLE: 'Deposit',
          QUERY_URL: 'deposit',
          CURRENCY_TYPE_TAB: {
            TITLE: 'Currency Type',
            QUERY_NAME: 'currencyTypeTab',
            DEFAULT_QUERY_URL: 'crypto',
            ROUTES: {
              CRYPTO: {
                TITLE: 'Crypto',
                QUERY_URL: 'crypto',
              },
              FIAT: {
                TITLE: 'Fiat',
                QUERY_URL: 'fiat',
              },
            },
          },
        },
        WITHDRAW: {
          TITLE: 'Withdraw',
          QUERY_URL: 'withdraw',
          CURRENCY_TYPE_TAB: {
            TITLE: 'Currency Type',
            QUERY_NAME: 'currencyTypeTab',
            DEFAULT_QUERY_URL: 'crypto',
            ROUTES: {
              CRYPTO: {
                TITLE: 'Crypto',
                QUERY_URL: 'crypto',
              },
              FIAT: {
                TITLE: 'Fiat',
                QUERY_URL: 'fiat',
              },
            },
          },
        },
        SWAP: {
          TITLE: 'Swap',
          QUERY_URL: 'swap',
        },
      },
    },
    HISTORY: {
      TITLE: t('HISTORY.TITLE'),
      QUERY_NAME: 'tab',
      DEFAULT_QUERY_URL: 'depowd',
      ROUTES: {
        PLAY: {
          TITLE: t('HISTORY.ROUTES.PLAY'),
          QUERY_URL: 'play',
        },
        DEPOWD: {
          TITLE: t('HISTORY.ROUTES.DEPOWD'),
          QUERY_URL: 'depowd',
        },
        SWAP: {
          TITLE: t('HISTORY.ROUTES.SWAP'),
          QUERY_URL: 'swap',
        },
        TRANSFER_GAME: {
          TITLE: t('HISTORY.ROUTES.TRANSFER_GAME'),
          QUERY_URL: 'transfergame',
        },
      },
    },
    GAME_PROVIDER: {
      TITLE: 'TEST',
      QUERY_NAME: 'gpId',
      DEFAULT_QUERY_URL: '',
    },
  };

  return { appTabs };
}

export default useAppTabs;
