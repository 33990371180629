import React from 'react';
import SlideModalComponent from '../../../../atoms/SlideModalComponent';
import useEditProfileStore from '../../stores/useEditProfileStore.store';
import InputComponent from '../../../../atoms/InputComponent';
import useAuthStore from '../../../../../stores/useAuthStore.store';
import ButtonComponent from '../../../../atoms/ButtonComponent';
import DatePickerComponent from '../../../../atoms/DatePickerComponent';

const EditProfile = () => {
  const { user } = useAuthStore((state) => ({
    user: state.user,
  }));
  const { openEditProfileModal, setCloseEditProfileModal } = useEditProfileStore((state) => ({
    openEditProfileModal: state.openEditProfileModal,
    setCloseEditProfileModal: state.setCloseEditProfileModal,
  }));

  return (
    <React.Fragment>
      <SlideModalComponent
        isOpen={openEditProfileModal}
        onClose={setCloseEditProfileModal}
        slideMode="toTop"
        title="Edit Profile"
      >
        <div className="space-y-2">
          <InputComponent label="Fullname" value={user?.fullname} placeholder="Input fullname" />
          <DatePickerComponent />
          <InputComponent label="Street Address" value={user?.address} placeholder="Input street address" />
          <InputComponent label="City" value={user?.city} placeholder="Input city" />
          <InputComponent label="State / Province" value={user?.province} placeholder="Input state / province" />
          <InputComponent label="Zip Code" value={user?.postalCode} placeholder="Input zip code" />
          <ButtonComponent buttonName="Coming Soon" disabled />
        </div>
      </SlideModalComponent>
    </React.Fragment>
  );
};

export default EditProfile;
