import React, { useEffect, useState } from 'react';
import DropDown from '../DropDown';

const DatePickerComponent = ({ setInputDay, setInputMonth, setInputYear }) => {
  const [day, setDay] = useState('');
  const [month /*, setMonth */] = useState('');
  const [year /*, setYear */] = useState('');
  const [daysInMonth, setDaysInMonth] = useState(31);

  useEffect(() => {
    updateDaysInMonth(month, year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year]);

  const updateDaysInMonth = (month, year) => {
    if (month && year) {
      const days = new Date(year, month, 0).getDate();
      setDaysInMonth(days);
      if (day > days) {
        setDay('');
      }
    }
  };

  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(
        <div key={i} className="">
          {i}
        </div>
      );
    }
    return options;
  };

  return (
    <React.Fragment>
      <div className="flex flex-row gap-2">
        <DropDown mainMenu={'Day'} dropdownMenu={generateOptions(1, daysInMonth)}></DropDown>
        <DropDown mainMenu={'Month'} dropdownMenu={generateOptions(1, 12)}></DropDown>
        <DropDown mainMenu={'Year'} dropdownMenu={generateOptions(1900, new Date().getFullYear())}></DropDown>
        {/* <select value={day} onChange={(e) => setDay(e.target.value)}>
          <option value="">Day</option>
          {generateOptions(1, daysInMonth)}
        </select>
        <select
          value={month}
          onChange={(e) => {
            setMonth(e.target.value);
            updateDaysInMonth(e.target.value, year);
          }}
        >
          <option value="">Month</option>
          {generateOptions(1, 12)}
        </select>
        <select
          value={year}
          onChange={(e) => {
            setYear(e.target.value);
            updateDaysInMonth(month, e.target.value);
          }}
        >
          <option value="">Year</option>
          {generateOptions(1900, new Date().getFullYear())}
        </select> */}
        {/* <p>
          Selected Date: {day}-{month}-{year}
        </p> */}
      </div>
    </React.Fragment>
  );
};

export default DatePickerComponent;
