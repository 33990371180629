import { create } from 'zustand';

const userAppStore = create((set, get) => ({
  modalApp: false,
  setOpenModalApp: () => {
    set({ modalApp: true });
  },
  setCloseModalApp: () => {
    set({ modalApp: false });
  },
}));

export default userAppStore;
