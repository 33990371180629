const dayjs = require('dayjs');

const formatDateTime = (datetime) => {
  return dayjs(datetime).format('DD/MMM/YYYY HH:mm:ss Z');
};

const formatDate = (datetime) => {
  return dayjs(datetime).format('DD/MMM/YYYY');
};

const formatTime = (datetime) => {
  return dayjs(datetime).format('HH:mm:ss');
};

const formatTimeZone = (datetime) => {
  return dayjs(datetime).format('Z');
};

const formatDateUtils = { formatDateTime, formatDate, formatTime, formatTimeZone };

export default formatDateUtils;
