// gameStore.js
import { create } from 'zustand';
// import sessionStorageUtils from '../utils/sessionStorage.utils';

const useGameStore = create((set, get) => {
  // const initAllGame = sessionStorageUtils.get('gamedata') ? JSON.parse(sessionStorageUtils.getItem('allGame')) : [];
  return {
    allGame: [],
    isAllGameStored: false,
    gameByCategoryData: {
      cg: [],
      lc: [],
      sl: [],
      lt: [],
    },
    isGameCategoryStored: false,
    setAllGame: (allGame) => {
      console.log('allGame : ', allGame);
      set({ allGame: allGame, isAllGameStored: true });
    },
    setGameByCategoryData: (gameData) => {
      // const oldGameByCategoryData = get().gameByCategoryData;

      // const newGameByCategoryData = {
      //   ...oldGameByCategoryData,
      //   [category]: gameData ?? [],
      // };

      set({ gameByCategoryData: gameData, isGameCategoryStored: true });
    },
    gamesData: [],
    setGamesData: (category, data) =>
      set((state) => ({
        gamesData: {
          ...state.gamesData,
          [category]: data,
        },
      })),
  };
});

export default useGameStore;
