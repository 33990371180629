/* eslint-disable no-useless-computed-key */
import React from 'react';

import { ReactComponent as APIcon } from './icons/APadvantplay.svg';
import { ReactComponent as HabaneroIcon } from './icons/Habanero.svg';
import { ReactComponent as HKBIcon } from './icons/HKBgaming.svg';
import { ReactComponent as PGSoftIcon } from './icons/PGsoft.svg';
import { ReactComponent as PTIcon } from './icons/playtech.svg';
import { ReactComponent as PPIcon } from './icons/pragmatic.svg';
import { ReactComponent as PTGIcon } from './icons/ptgaming.svg';
import { ReactComponent as SABAIcon } from './icons/saba.svg';
import { ReactComponent as SBOIcon } from './icons/sbobet.svg';
import { ReactComponent as SBIcon } from './icons/sexybuccarat.svg';
import { ReactComponent as SGIcon } from './icons/spadegaming.svg';
import { ReactComponent as SAIcon } from './icons/SAgaming.svg';

const GameProviderImage = ({ name, className, ...props }) => {
  const icons = {
    HB: { Component: HabaneroIcon, className: 'fill-text-color-primary' },
    SBO: { Component: SBOIcon, className: 'fill-text-color-primary' },
    SABA: { Component: SABAIcon, className: 'fill-text-color-primary' },
    SA: { Component: SAIcon, className: 'fill-text-color-primary' },
    PP: { Component: PPIcon, className: 'fill-text-color-primary' },
    PG: { Component: PGSoftIcon, className: 'fill-text-color-primary' },
    PTG: { Component: PTGIcon, className: 'fill-text-color-primary' },
    SB: { Component: SBIcon, className: 'fill-text-color-primary' },
    AP: { Component: APIcon, className: 'fill-text-color-primary' },
    HKB: { Component: HKBIcon, className: 'fill-text-color-primary' },
    PT: { Component: PTIcon, className: 'fill-text-color-primary' },
    SG: { Component: SGIcon, className: 'fill-text-color-primary' },
    // ... other icons with their respective class names
  };

  const iconData = icons[name];
  if (!iconData) {
    return null;
  }

  const { Component: IconComponent, className: iconClassName } = iconData;
  const combinedClassName = `${iconClassName} ${className || ''}`.trim();

  return <IconComponent className={combinedClassName} {...props} />;
};

export default GameProviderImage;
