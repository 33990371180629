import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useNotification from '../../../hooks/useNotification.hook';

const CopyContent = ({ children, text }) => {
  const { notifySuccess } = useNotification();
  // const [copied, setCopied] = useState(false);

  const onCopy = () => {
    notifySuccess('Copy address success');
    // setCopied(true);
    // setTimeout(() => {
    //   setCopied(false);
    // }, 2000); // Hide notification after 2 seconds
  };

  return (
    <React.Fragment>
      <CopyToClipboard text={text} onCopy={onCopy}>
        {children}
      </CopyToClipboard>
      {/* {copied && (
        <div className="fixed bottom-5 z-1000 left-1/2 transform -translate-x-1/2 text-text-color-primary text-center p-2 rounded-lg shadow-lg">
          Copied!
        </div>
      )} */}
    </React.Fragment>
  );
};

export default CopyContent;
