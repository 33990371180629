import React from 'react';
import Header from '../../organisms/Header';
import Footer from '../../organisms/Footer';
import { Outlet } from 'react-router-dom';
import Content from '../../organisms/Content';
import NavigationBar from '../../organisms/NavigationBar';
import { ToastContainer } from 'react-toastify';
import Sidebar from '../../organisms/Sidebar';

const RootLayout = () => {
  return (
    <div className="flex flex-col text-text-color-primary bg-body-color-primary overflow-y-auto mb-12">
      {/* <div className="flex-1 overflow-y-auto"> */}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Content>
        <Outlet />
      </Content>
      <Footer />
      <NavigationBar />
      {/* </div> */}
      <Sidebar />
    </div>
  );
};

export default RootLayout;
