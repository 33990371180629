/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import 'swiper/css';
import useApiRequest from '../../../../../hooks/useApiRequest.hook';
import useBrandStore from '../../../../../stores/useBrandStore.store';
import useAuthStore from '../../../../../stores/useAuthStore.store';
import useAppTabs from '../../../../../hooks/useAppTabs.hook';
// import NavigationComponent from '../../../../atoms/NavigationComponent';
// import appRoutes from '../../../../../core/routes/routes';
import GameProviderImage from '../../../../atoms/GameProviderImage';
import { useSearchParams } from 'react-router-dom';
SwiperCore.use([Navigation]);

const GameProviderPageComponent = () => {
  const { data, /* loading, error, */ post } = useApiRequest('/gameNoAuth');
  const { brandId } = useBrandStore();
  const { token, isAuthenticated } = useAuthStore();
  const { appTabs } = useAppTabs();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();

  const { QUERY_NAME /* , TITLE */ } = appTabs.GAME_PROVIDER;
  const gpIdQuery = urlSearchParams.get(QUERY_NAME);

  const [gameProviderData, setGameProviderData] = useState([]);
  const [selectedGameProvider, setSelectedGameProvider] = useState(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (token && isAuthenticated) {
      getGames();
    }
  }, [token, isAuthenticated]);

  useEffect(() => {
    if (data) {
      setGameProviderData(data?.data);
      const selectedGpId = data?.data?.filter((item) => item?.gpid === gpIdQuery);
      setSelectedGameProvider(selectedGpId[0]);
    }
  }, [data]);

  const getGames = useCallback(() => {
    post({
      options: {
        headers: {
          Authorization: token,
        },
      },
      data: {
        systemType: 'gameProvider',
        gameCategory: 'all',
        brandId: brandId,
      },
    });
  }, [post, token]);

  const onNavigateHandler = (gpid) => {
    setUrlSearchParams({ [QUERY_NAME]: gpid });
  };

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <React.Fragment>
      <div className="space-y-4 pb-6">
        <div className="flex justify-between items-center leading-none">
          <div className="text-base font-semibold">Game by {selectedGameProvider?.gpname}</div>

          <div className="space-x-4">
            <KeyboardArrowLeftIcon onClick={goPrev} />
            <KeyboardArrowRightIcon onClick={goNext} />
          </div>
        </div>
        <Swiper ref={swiperRef} slidesPerView={'2.5'} spaceBetween={16} className="mySwiper">
          {gameProviderData.map((item, index) => (
            <React.Fragment key={index}>
              {item?.gplogo && (
                <SwiperSlide key={index}>
                  <div
                    className={`
                      h-16 rounded-xl p-2 cursor-pointer
                      ${gpIdQuery === item?.gpid ? 'bg-navbar-active-color-primary' : 'bg-body-color-tertiary'}
                    `}
                    onClick={() => {
                      onNavigateHandler(item?.gpid);
                      setSelectedGameProvider(item);
                    }}
                  >
                    {item?.gplogo ? (
                      <GameProviderImage name={item?.gpid} className={'w-full h-full'} />
                    ) : (
                      // <img src={item?.gplogo} alt="" className="w-full h-full object-cover" />
                      <div className="flex w-full h-full items-center justify-center text-sm">
                        <span className="text-center">{item?.gpname}</span>
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              )}
            </React.Fragment>
          ))}
        </Swiper>
      </div>
    </React.Fragment>
  );
};

export default GameProviderPageComponent;
