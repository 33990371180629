/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SearchComponent from "../../atoms/SearchComponent";
// import useApiRequest from '../../../hooks/useApiRequest.hook';
import CardGame from "../../atoms/CardGame";
// import useAuthStore from '../../../stores/useAuthStore.store';
// import { LoadingDot } from '../../atoms/Loading';
// import useBrandStore from '../../../stores/useBrandStore.store';
import SlideModalComponent from "../../atoms/SlideModalComponent";
import useGameStore from "../../../stores/useGameStore";

// const GameSearchComponent = () => {
//   const { data, loading, post } = useApiRequest('/searchGame');

//   const { brandId } = useBrandStore();
//   const { token, isAuthenticated } = useAuthStore();
//   const { allGame } = useGameStore();

//   const [searchGame, setSearchGame] = useState('');
//   const [openSearchModal, setOpenSearchModal] = useState(false);
//   const [isSearched, setIsSearched] = useState(false);
//   const [games, setGames] = useState({
//     totalGames: 0,
//     gameData: [],
//   });

//   const [gamesPayload, setGamesPayload] = useState({
//     brandId: brandId,
//     gameCategory: '',
//     gpId: [],
//     from: 0,
//     limit: 24,
//     search: '',
//     orderBy: 'desc',
//   });

//   useEffect(() => {
//     console.log('isAuthenticated : ', isAuthenticated);
//     console.log('token : ', token);
//     console.log('openSearchModal : ', openSearchModal);
//     console.log('isSearched : ', isSearched);
//     if (isAuthenticated && token && openSearchModal && !isSearched) {
//       console.log('kenapa gk jalan');
//       getSearchGame();
//     }
//   }, [isAuthenticated, token, openSearchModal, isSearched]);

//   useEffect(() => {
//     // Safely access the data properties
//     const newGames = data?.data?.body || [];
//     const total = data?.data?.total || 0;

//     setGames((prev) => {
//       if (gamesPayload.from === 0) {
//         return {
//           totalGames: total,
//           gameData: newGames,
//         };
//       } else {
//         return {
//           totalGames: total,
//           gameData: [...prev.gameData, ...newGames],
//         };
//       }
//     });
//   }, [data]);

//   useEffect(() => {
//     setGamesPayload((prev) => ({
//       ...prev,
//       search: searchGame,
//       from: 0, // Resetting the from value whenever search term changes
//     }));
//   }, [searchGame]);

//   const changeGamesPayload = () => {
//     setGamesPayload((prev) => {
//       const updatedPayload = {
//         ...prev,
//         from: prev.from + prev.limit,
//       };

//       // Use the updated payload to get search games
//       getSearchGameUsingPayload(updatedPayload);

//       return updatedPayload;
//     });
//   };

//   const getSearchGameUsingPayload = (payload) => {
//     post({
//       options: {
//         headers: {
//           Authorization: token,
//         },
//       },
//       data: payload,
//     });
//     setIsSearched(true);
//   };

//   const getSearchGame = () => {
//     getSearchGameUsingPayload(gamesPayload);
//   };

//   return (
//     <React.Fragment>
//       <div className="block leading-none space-y-2 pb-6">
//         {/* <div className="text-center">Search Game</div> */}
//         <div className="w-full h-10" onClick={() => setOpenSearchModal(true)}>
//           <SearchComponent value={searchGame} onChange={setSearchGame} readOnly />
//         </div>
//       </div>

//       <SlideModalComponent
//         isOpen={openSearchModal}
//         onClose={() => {
//           setOpenSearchModal(false);
//           setSearchGame('');
//           setGames({
//             gameData: [],
//             totalGames: 0,
//           });
//           setIsSearched(false);
//         }}
//         title="Search Game"
//         slideMode="toTop"
//       >
//         <div className="space-y-4">
//           <div className="w-full h-10">
//             <SearchComponent value={searchGame} onChange={setSearchGame} onSearch={getSearchGame} />
//           </div>
//           <div className="grid grid-cols-3 gap-2">
//             {games.gameData.map((game, index) => (
//               // <div className="h-32" key={game.id}>
//               <CardGame key={index} game={game} />
//               // </div>
//             ))}
//           </div>
//           {loading && (
//             <div className="">
//               <LoadingDot />
//             </div>
//           )}
//           <div className="">
//             {games.totalGames > games.gameData.length && (
//               <React.Fragment>
//                 {!loading && (
//                   <div className="text-center cursor-pointer" onClick={changeGamesPayload}>
//                     Click to load more games
//                   </div>
//                 )}
//               </React.Fragment>
//             )}
//           </div>
//         </div>
//       </SlideModalComponent>
//     </React.Fragment>
//   );
// };

const GameSearchComponent = () => {
  // const { data, loading, post } = useApiRequest('/searchGame');

  // const { brandId } = useBrandStore();
  // const { token, isAuthenticated } = useAuthStore();
  const { allGame } = useGameStore((state) => ({
    allGame: state.allGame,
  }));

  const [searchGame, setSearchGame] = useState("");
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [, /* isSearched */ setIsSearched] = useState(false);
  const [, /* games */ setGames] = useState(allGame ?? []);
  const [searchGameData, setSearchGameData] = useState(allGame);

  // const [gamesPayload, setGamesPayload] = useState({
  //   brandId: brandId,
  //   gameCategory: '',
  //   gpId: [],
  //   from: 0,
  //   limit: 24,
  //   search: '',
  //   orderBy: 'desc',
  // });

  useEffect(() => {
    setGames(allGame);
    setSearchGameData(allGame);
  }, [allGame]);

  useEffect(() => {
    if (searchGame) {
      console.log("jalan ada isi : ", searchGame);
      searchGames();
    } else {
      console.log("jalan kosong");
      setSearchGameData(allGame);
    }
  }, [searchGame]);

  function searchGames() {
    console.log("jalan ada isi : ", searchGame);
    console.log("searchGameData : ", searchGameData);

    const searchTerm = searchGame.toLowerCase();
    const newSearchGameData = searchGameData.filter(
      (game) =>
        game.gameName.toLowerCase().includes(searchTerm) ||
        game.gpId.toLowerCase().includes(searchTerm) ||
        game.gameCategoryName.toLowerCase().includes(searchTerm) ||
        game.gpName.toLowerCase().includes(searchTerm)
    );
    setSearchGameData(newSearchGameData);
  }

  // const getSearchGameUsingPayload = (payload) => {
  //   // post({
  //   //   options: {
  //   //     headers: {
  //   //       Authorization: token,
  //   //     },
  //   //   },
  //   //   data: payload,
  //   // });
  //   setIsSearched(true);
  // };

  const getSearchGame = () => {
    // getSearchGameUsingPayload(gamesPayload);
  };

  return (
    <React.Fragment>
      <div className="block leading-none space-y-2 pb-6">
        {/* <div className="text-center">Search Game</div> */}
        <div className="w-full h-10" onClick={() => setOpenSearchModal(true)}>
          <SearchComponent
            value={searchGame}
            onChange={setSearchGame}
            readOnly
          />
        </div>
      </div>

      <SlideModalComponent
        isOpen={openSearchModal}
        onClose={() => {
          setOpenSearchModal(false);
          setSearchGame("");
          setGames({
            gameData: [],
            totalGames: 0,
          });
          setIsSearched(false);
        }}
        title="Search Game"
        slideMode="toTop"
      >
        <div className="space-y-4">
          <div className="w-full h-10">
            <SearchComponent
              value={searchGame}
              onChange={setSearchGame}
              onSearch={getSearchGame}
            />
          </div>
          <div className="grid grid-cols-3 gap-2">
            {searchGameData.map((game, index) => (
              // <div className="h-32" key={game.id}>
              <CardGame key={index} game={game} />
              // </div>
            ))}
          </div>
          {/* {loading && (
            <div className="">
              <LoadingDot />
            </div>
          )}
          <div className="">
            {games.totalGames > games.gameData.length && (
              <React.Fragment>
                {!loading && (
                  <div className="text-center cursor-pointer" onClick={changeGamesPayload}>
                    Click to load more games
                  </div>
                )}
              </React.Fragment>
            )}
          </div> */}
        </div>
      </SlideModalComponent>
    </React.Fragment>
  );
};

export default GameSearchComponent;
