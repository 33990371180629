import React from 'react';
import ButtonComponent from '../../atoms/ButtonComponent';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../../core/routes/routes';

const Banner = () => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate(appRoutes.WALLET);
  };
  return (
    <React.Fragment>
      <div className="pb-6">
        <div className="relative aspect-2/1">
          <div className="w-full h-full">
            <img
              src="/images/banner/banner-image.png"
              alt="Description"
              className="w-full h-full rounded-xl object-cover"
              loading="lazy"
            />
            <div className="absolute bottom-4 left-4 text-text-color-primary font-bold text-xl">
              <ButtonComponent buttonName="Deposit" onClick={onClickHandler} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Banner;
