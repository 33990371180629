import React from "react";

const InputComponent = ({
  id = "inputId",
  type = "text",
  notes,
  info,
  label = "Label Input",
  disabledLabel = false,
  value,
  onChange,
  placeholder = "Input",
  disabled = false,
}) => {
  // const [input, setInput] = useState(value);

  const onChangeHandler = (e) => {
    const inputValue = e.target.value;
    // setInput(inputValue);
    if (onChange) {
      onChange(inputValue);
    }
  };
  return (
    <React.Fragment>
      <div className="block w-full space-y-1">
        {!disabledLabel && <div className="text-base">{label}</div>}
        <input
          id={id}
          type={type}
          value={value}
          onChange={onChangeHandler}
          className="w-full h-8 px-3 text-sm appearance-none rounded-xl bg-body-color-secondary text-text-color-primary"
          placeholder={placeholder}
          disabled={disabled}
        />
        {info && <p className="text-xs">{info}</p>}
        {notes && <p className="text-red-600 text-xs">{notes}</p>}
      </div>
    </React.Fragment>
  );
};

export default InputComponent;
