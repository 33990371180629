import appRoutes from './routes';
import RootLayout from '../../components/templates/RootLayout';
import HomePage from '../../components/pages/HomePage';
import GameCategoryPage from '../../components/pages/GameCategoryPage';
import NotFoundPage from '../../components/pages/NotFoundPage';
import AccountPage from '../../components/pages/AccountPage';
import PlayPage from '../../components/pages/PlayPage';
import WalletPage from '../../components/pages/WalletPage';
import HistoryPage from '../../components/pages/HistoryPage';
import RewardsPage from '../../components/pages/RewardsPage';
import ReferralPage from '../../components/pages/ReferralPage';
import GameProviderPage from '../../components/pages/GameProviderPage';

const routesConfig = [
  {
    element: <RootLayout />,
    children: [
      {
        path: appRoutes.ACCOUNT,
        element: <AccountPage />,
      },
      {
        path: appRoutes.GAME_CATEGORY,
        element: <GameCategoryPage />,
      },
      {
        path: appRoutes.GAME_PROVIDER,
        element: <GameProviderPage />,
      },
      {
        path: appRoutes.HISTORY,
        element: <HistoryPage />,
      },
      {
        path: appRoutes.HOME,
        element: <HomePage />,
      },
      {
        path: appRoutes.REFERRAL,
        element: <ReferralPage />,
      },
      {
        path: appRoutes.REWARDS,
        element: <RewardsPage />,
      },
      {
        path: appRoutes.PLAY,
        element: <PlayPage />,
      },
      {
        path: appRoutes.WALLET,
        element: <WalletPage />,
      },
      {
        path: appRoutes.UNKNOWN,
        element: <NotFoundPage />,
      },
    ],
  },
];

export default routesConfig;
