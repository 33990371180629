import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import './tailwind.scss';
import { SDKProvider } from '@tma.js/sdk-react';
import TmaLoader from './components/templates/TmaLoader';
import awsAppSyncConfig from './config/awsAppsync.config';
import { Amplify } from 'aws-amplify';
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18next.utils';
import 'react-toastify/dist/ReactToastify.css';
// import '../src/utils/custom-string.utils';

Amplify.configure(awsAppSyncConfig);

const renderRoot = () => {
  const rootElement = ReactDOM.createRoot(document.getElementById('root'));

  rootElement.render(
    // <React.StrictMode>
    <SDKProvider
      initOptions={{
        debug: true,
        cssVars: true,
      }}
    >
      <TmaLoader>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </TmaLoader>
    </SDKProvider>
    // </React.StrictMode>
  );
};

renderRoot();
