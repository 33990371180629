/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
// import useFetchApi from './useFetchApi.hook';
import useBrandStore from "../stores/useBrandStore.store";
import useAuthStore from "../stores/useAuthStore.store";
import useGameStore from "../stores/useGameStore";
import { GameApiService } from "../services/api/player/game.api.services";
import sessionStorageUtils from "../utils/sessionStorage.utils";

const useGetterApp = () => {
  const { brandId } = useBrandStore();
  const { token, isAuthenticated } = useAuthStore();
  const {
    isAllGameStored,
    isGameCategoryStored,
    setAllGame,
    setGameByCategoryData,
  } = useGameStore();
  const allGameSessionStorage = sessionStorageUtils.get("allgamedata");
  const gameCategorySessionStorage =
    sessionStorageUtils.get("gamecateogrydata");

  useEffect(() => {
    if (
      token &&
      isAuthenticated &&
      !isAllGameStored &&
      !allGameSessionStorage
    ) {
      fetchAllGameData();
    }
  }, [
    token,
    isAuthenticated,
    isAllGameStored,
    allGameSessionStorage,
    gameCategorySessionStorage,
  ]);

  useEffect(() => {
    if (
      token &&
      isAuthenticated &&
      !isGameCategoryStored &&
      !gameCategorySessionStorage
    ) {
      fetchGameByCategoryData();
    }
  }, [
    token,
    isAuthenticated,
    isGameCategoryStored,
    gameCategorySessionStorage,
  ]);

  useEffect(() => {
    if (allGameSessionStorage && !isAllGameStored) {
      console.log("allGameSessionStorage : ", allGameSessionStorage);
      setAllGame(allGameSessionStorage?.body);
    }
  }, [allGameSessionStorage, isAllGameStored]);

  useEffect(() => {
    if (gameCategorySessionStorage && !isGameCategoryStored) {
      console.log("gameCategorySessionStorage : ", gameCategorySessionStorage);
      setGameByCategoryData(gameCategorySessionStorage);
    }
  }, [gameCategorySessionStorage, isGameCategoryStored]);

  const fetchAllGameData = async () => {
    try {
      const data = await GameApiService.SearchGame({
        headers: {
          Authorization: token,
        },
        data: {
          brandId: brandId,
          search: "",
          limit: 10000,
          from: 0,
          orderBy: "desc",
        },
      });

      console.log("data : ", data);
      sessionStorageUtils.set("allgamedata", data?.data);
      setAllGame(data?.data?.body);
    } catch (error) {
      console.log("error fetchAllGameData : ", error);
    }
  };

  const fetchGameByCategoryData = async () => {
    try {
      const data = await GameApiService.GameNoAuth({
        headers: {
          Authorization: token,
        },
        data: {
          systemType: "getHomeCategoryGame",
          brandId: brandId,
          gameCategory: "all",
        },
      });
      console.log("data : ", data);

      sessionStorageUtils.set("gamecateogrydata", data?.data);
      const gameData = {};

      for (const item in data?.data) {
        console.log("item : ", item);
        Object.assign(gameData, { [item]: data?.data?.[item] });
        // setGameByCategoryData(item, data?.data?.[item]);
      }

      setGameByCategoryData(gameData);
    } catch (error) {
      console.log("error fetchGameByCategoryData : ", error);
    }
  };

  return {};
};

export default useGetterApp;
