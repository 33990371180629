/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import WalletDeposit from '../../organisms/WalletDeposit';
import WalletWithdraw from '../../organisms/WalletWithdraw';
import NavigationTab from '../../organisms/NavigationTab';
import useAppTabs from '../../../hooks/useAppTabs.hook';
import { useSearchParams } from 'react-router-dom';
import Swap from '../../organisms/Swap';

const WalletPage = () => {
  const { appTabs } = useAppTabs();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    const existQuery = urlSearchParams.get(appTabs.WALLET.QUERY_NAME);
    if (!existQuery) {
      setUrlSearchParams({ [appTabs.WALLET.QUERY_NAME]: appTabs.WALLET.DEFAULT_QUERY_URL });
    }
  }, []);

  useEffect(() => {
    setSelectedTab(urlSearchParams.get(appTabs.WALLET.QUERY_NAME));
  }, [urlSearchParams]);

  return (
    <React.Fragment>
      <NavigationTab queryName={appTabs.WALLET.QUERY_NAME} tabs={appTabs.WALLET.ROUTES} />

      <div className="w-full h-full">
        {selectedTab === appTabs.WALLET.ROUTES.DEPOSIT.QUERY_URL && <WalletDeposit />}
        {selectedTab === appTabs.WALLET.ROUTES.WITHDRAW.QUERY_URL && <WalletWithdraw />}
        {selectedTab === appTabs.WALLET.ROUTES.SWAP.QUERY_URL && <Swap />}
      </div>
    </React.Fragment>
  );
};

export default WalletPage;
