const set = (key, value) => {
  if (!key || value === undefined) {
    return;
  }

  if (typeof value === 'object' && value !== null) {
    value = JSON.stringify(value);
  }

  localStorage.setItem(key, value);
};

const setMap = (key, map) => {
  const mapAsArray = Array.from(map);
  localStorage.setItem(key, JSON.stringify(mapAsArray));
};

const get = (key) => {
  if (!key) {
    return null;
  }

  const value = localStorage.getItem(key);

  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

const getMap = (key) => {
  const mapAsArray = JSON.parse(localStorage.getItem(key));
  return new Map(mapAsArray);
};

const remove = (key) => {
  if (!key) {
    return;
  }
  localStorage.removeItem(key);
};

const clearSpesificKey = () => {
  remove('userBalance');
  remove('userToken');
};

const clearAll = () => {
  localStorage.clear();
};

const localStorageUtils = {
  set,
  setMap,
  get,
  getMap,
  remove,
  clearSpesificKey,
  clearAll,
};

export default localStorageUtils;
