import React from 'react';
import CurrencyInput from 'react-currency-input-field';

const CurrencyInputComponent = ({
  id = 'inputId',
  label = 'Label Input',
  disabledLabel = false,
  value,
  onChange,
  currencySymbol,
  disabled = false,
  notes,
  info,
}) => {
  const onChangeHandler = (value, name) => {
    if (onChange) {
      if (value) {
        onChange(value);
      } else {
        onChange(0);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="block w-full space-y-1">
        {!disabledLabel && <div className="text-base">{label}</div>}
        <CurrencyInput
          id={id}
          name="amount"
          placeholder="0"
          value={value}
          className="w-full h-8 px-3 text-sm appearance-none focus:outline-none focus:border-none focus:ring-0 rounded-xl bg-body-color-secondary text-text-color-primary"
          decimalsLimit={6}
          onValueChange={onChangeHandler}
          min={0}
          allowNegativeValue={false}
          disabled={disabled}
          
        />
        {info && <p className="text-xs">{info}</p>}
        {notes && <p className="text-red-600 text-xs">{notes}</p>}
      </div>
    </React.Fragment>
  );
};

export default CurrencyInputComponent;
