import axios from 'axios';
import { handleApiError } from '../../utils/errorHandler';

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const errorMessage = handleApiError(error.response.data);
      console.log('errorMessage : ', errorMessage);
      return Promise.reject(errorMessage);
    }
    return Promise.reject(error.message);
  }
);

const api = async ({ method, url, data, headers, ...options }) => {
  const response = await apiInstance({
    method,
    url,
    data,
    headers: {
      ...apiInstance.defaults.headers,
      ...headers,
    },
    ...options,
  });
  return response.data;
};

export default api;
