import React from 'react';

const Content = ({ children }) => {
  return (
    <React.Fragment>
      <div className="containerContent min-h-screen flex-grow no-scrollbar">{children}</div>
    </React.Fragment>
  );
};

export default Content;
