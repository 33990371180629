import { toast } from 'react-toastify';

const useNotification = () => {
  const notifySuccess = (text, options = {}) => {
    toast.success(text, options);
  };

  const notifyError = (text, options = {}) => {
    toast.error(text, options);
  };

  const notifyWarning = (text, options = {}) => {
    toast.warning(text, options);
  };

  const notifyInfo = (text, options = {}) => {
    toast.info(text, options);
  };

  const notifyDefault = (text, options = {}) => {
    toast(text, options);
  };

  return {
    notifySuccess,
    notifyError,
    notifyWarning,
    notifyInfo,
    notifyDefault,
  };
};

export default useNotification;
