/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import userAppStore from '../../../stores/useAppStore.store';

const ModalComponent = ({ isOpen, onClose, title = 'Popup', children }) => {
  const { setOpenModalApp, setCloseModalApp } = userAppStore();

  useEffect(() => {
    if (isOpen) {
      setOpenModalApp();
    }
  }, [isOpen]);

  const onCloseHandler = () => {
    setCloseModalApp();
    if (onClose) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="fixed inset-0 w-full min-h-screen flex items-center justify-center z-50 overflow-y-auto">
        {/* <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div> */}
        <div className="relative w-full h-screen bg-body-color-primary overflow-y-auto no-scrollbar space-y-4">
          <div className="flex justify-between items-center p-4 text-text-color-primary bg-body-color-secondary">
            <h3 className="text-lg font-semibold">{title}</h3>
            <CloseOutlinedIcon className="cursor-pointer" onClick={onCloseHandler} />
          </div>
          <div className="px-4">{children}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ModalComponent;
