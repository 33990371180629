import { create } from "zustand";
import api from "../services/api/api";

const useBrandStore = create((set, get) => ({
  brandId: "DTLG1",
  setBrandId: (brandId) => set({ brandId }),
  brandSetting: null,
  setBrandSetting: async (token) => {
    const res = await api({
      url: "/getBrandSetting2",
      method: "post",
      data: {
        brandId: get().brandId,
        type: "dynamic",
      },
    });

    set({
      brandSetting: res,
    });
  },
}));

export default useBrandStore;
