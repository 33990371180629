// src/services/api.js
import axios from 'axios';
import { handleApiError } from '../../utils/errorHandler';

const createApiInstance = (baseUrl) => {
  const apiInstance = axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  apiInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const errorMessage = handleApiError(error.response.data);
        return Promise.reject(new Error(errorMessage));
      }
      return Promise.reject(new Error(error.message));
    }
  );

  return apiInstance;
};

export const apiInstance = createApiInstance(process.env.REACT_APP_API_BASEURL);
export const apiInstanceAffiliate = createApiInstance(process.env.AFF_URL);
