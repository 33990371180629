/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import useApiRequest from '../../../hooks/useApiRequest.hook';
import useAuthStore from '../../../stores/useAuthStore.store';

import formatDateUtils from '../../../utils/formatDate';
import walletUtils from '../../../utils/wallet.utils';
import { LoadingDot } from '../../atoms/Loading';

const HistoryPlay = () => {
  const { data, loading, post } = useApiRequest('/bet-report');
  const { token, isAuthenticated } = useAuthStore();

  const [playReportPayload, setPlayReportPayload] = useState({
    gpId: '',
    currency: '',
    orderBy: 'newest',
    lastItem: {},
  });

  const [playReportData, setPlayReportData] = useState([]);

  useEffect(() => {
    if (token && isAuthenticated) {
      getPlayReport();
    }
  }, [token, isAuthenticated]);

  useEffect(() => {
    if (data) {
      // const newArray = [];
      // const rawData = data?.data?.Items;

      // for (const item of rawData) {
      //   newArray.push(
      //     createData(
      //       `${item?.gameName} ${item?.gpName}`,
      //       formatDate.format(item?.transactionDate),
      //       `${item?.txCurrency} ${item?.amountOrigin}`,
      //       item?.transactionType
      //     )
      //   );
      // }

      // setPlayReportData([...newArray]);

      setPlayReportData((prev) => {
        return [...prev, ...data?.data?.Items];
      });

      setPlayReportPayload((prev) => {
        return {
          ...prev,
          lastItem: Object.keys(data?.data?.LastEvaluatedKey ?? {}).length ?? {},
        };
      });
    }
  }, [data]);

  const getPlayReportLoadMore = () => {
    setPlayReportPayload((prev) => {
      const updatedPayload = {
        ...prev,
      };
      getPlayReportUsingPayload(updatedPayload);

      return updatedPayload;
    });
  };

  const getPlayReportUsingPayload = (payload) => {
    post({
      options: {
        headers: {
          Authorization: token,
        },
      },
      data: payload,
    });
  };

  const getPlayReport = () => {
    getPlayReportUsingPayload(playReportPayload);
  };

  return (
    <React.Fragment>
      <div className="text-xs">
        <table className="w-full">
          <thead>
            <tr className="flex">
              <th className="flex-1 px-4 p-1 w-1/4 word-wrap break-word white-space-normal">Game</th>
              <th className="flex-1 px-4 p-1 w-1/4 word-wrap break-word white-space-normal">Datetime</th>
              <th className="flex-1 px-4 p-1 w-1/4 word-wrap break-word white-space-normal">Amount</th>
              <th className="flex-1 px-4 p-1 w-1/4 word-wrap break-word white-space-normal">Status</th>
            </tr>
          </thead>
          <tbody className="w-full">
            {!playReportData.length && loading && <LoadingDot />}
            {playReportData.map((item, index) => (
              <tr key={index} className="flex border-t items-center">
                <td className="p-1 w-1/4 text-start flex-1 word-wrap break-word white-space-normal">
                  <p>{item?.gameName}</p>
                  <p>{item?.gpName}</p>
                </td>
                <td className="p-1 w-1/4 text-start flex-1 word-wrap break-word white-space-normal">
                  <p>{formatDateUtils.formatDate(item?.transactionDate)}</p>
                  <p>{formatDateUtils.formatTime(item?.transactionDate)}</p>
                  <p>{formatDateUtils.formatTimeZone(item?.transactionDate)}</p>
                </td>
                <td className="p-1 w-1/4 text-end flex-1 word-wrap break-word white-space-normal">
                  <p>
                    {walletUtils.formatCurrency(item?.amount)}&nbsp;
                    <span>{item?.currency}</span>
                  </p>
                  {item?.currency !== item?.txCurrency && (
                    <p className="text-xxs">
                      {walletUtils.formatCurrency(item?.amountInUSD)}&nbsp;
                      <span>{item?.txCurrency}</span>
                    </p>
                  )}
                </td>
                <td className="p-1 w-1/4 text-end flex-1 word-wrap break-word white-space-normal">
                  {item?.transactionType === 'bet' && <p className="text-red-600">bet</p>}
                  {item?.transactionType === 'result' && <p className="text-green-600">win</p>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {Object.keys(playReportPayload.lastItem).length ? (
          <div className="text-center cursor-pointer" onClick={getPlayReportLoadMore}>
            Click to load more history
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default HistoryPlay;
