/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useAuthStore from '../../../../../stores/useAuthStore.store';
// import SearchComponent from '../../atoms/SearchComponent';
import useApiRequest from '../../../../../hooks/useApiRequest.hook';
import walletUtils from '../../../../../utils/wallet.utils';
import SlideModalComponent from '../../../../atoms/SlideModalComponent';
import useWalletIconStore from '../../stores/useWalletIconStore.store';

const WalletProfileModal = ({ isModalOpen, onModalClose }) => {
  const { data, post } = useApiRequest('/changeActiveWallet2');
  const { walletBalance, token, setActiveWallet, activeWalletBalance } = useAuthStore();
  const { openWalletModal, setCloseWalletModal } = useWalletIconStore((state) => ({
    openWalletModal: state.openWalletModal,
    setCloseWalletModal: state.setCloseWalletModal,
  }));

  // const [searchWallet, setSearchWallet] = useState('');

  // useEffect(() => {}, [walletBalance]);

  useEffect(() => {
    if (data) {
      setActiveWallet(data?.data?.activeWallet);
      setCloseWalletModal();
    }
  }, [data]);

  const changeActiveWallet = (currency) => {
    if (currency) {
      post({
        options: {
          headers: {
            Authorization: token,
          },
        },
        data: {
          selectedWallet: currency,
        },
      });
    }
  };

  const WalletList = ({ title = 'currency', currencyType }) => {
    return (
      <React.Fragment>
        <div className="space-y-2">
          <p className="text-sm text-text-color-secondary">{title}</p>
          <div className="w-full">
            {[...walletBalance.entries()]
              .filter(([, walletData]) => walletData.currencyType === currencyType)
              .sort()
              .map(([key, walletData], index) => (
                <React.Fragment key={index}>
                  <div
                    className={`
                      ${walletData?.currency === activeWalletBalance?.currency && 'bg-navbar-active-color-primary'}
                      flex justify-between w-full h-8 px-2 items-center hover:cursor-pointer hover:bg-navbar-active-color-primary hover:opacity-90
                    `}
                    onClick={() => {
                      changeActiveWallet(walletData.currency);
                    }}
                  >
                    <div className="flex space-x-2 w-32 h-full items-center">
                      <div className="w-7 h-7 aspect-square">
                        <img src={walletData?.currencyLogo} alt="" className="w-full h-full" loading="lazy" />
                      </div>
                      <p className="w-full text-left">{walletData?.currency}</p>
                    </div>
                    <p className="text-right">{walletUtils.formatCurrency(walletData?.totalBalance)}</p>
                  </div>
                </React.Fragment>
              ))}
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <SlideModalComponent isOpen={openWalletModal} onClose={setCloseWalletModal} title="Wallet" slideMode="toBottom">
        {/* <div className="h-10">
            <SearchComponent value={searchWallet} onChange={setSearchWallet} />
          </div>
          <div className="flex text-center">
            <div className="flex-1">Crypto</div>
            <div className="flex-1">Fiat</div>
          </div> */}

        <WalletList currencyType={'fiat'} title="Fiat" />
        <WalletList currencyType={'crypto'} title="Crypto" />
      </SlideModalComponent>
    </React.Fragment>
  );
};

export default WalletProfileModal;
