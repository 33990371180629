const { create } = require('zustand');

const useWalletIconStore = create((set) => ({
  openWalletModal: false,
  setOpenWalletModal: () => {
    set({ openWalletModal: true });
  },
  setCloseWalletModal: () => {
    set({ openWalletModal: false });
  },
}));

export default useWalletIconStore;
