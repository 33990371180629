/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';

const useFetchApi = (
  apiCallFunction,
  { shouldFetch = false, initialConfig = {}, dependencies = [], customCondition = () => true } = {}
) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    async (config = {}) => {
      setLoading(true);
      try {
        const result = await apiCallFunction(config);
        setData(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    },
    // Ensure apiCallFunction is stable to prevent unnecessary re-creations of fetchData
    [apiCallFunction]
  );

  // useEffect for initial fetch
  // useEffect(() => {
  // 	if (shouldFetch) {
  // 		fetchData(initialConfig);
  // 	}
  // 	// The empty dependency array ensures this runs only on the initial mount
  // }, []);

  useEffect(() => {
    if (shouldFetch && customCondition()) {
      fetchData(initialConfig);
    }
  }, [shouldFetch, ...dependencies]);

  const triggerFetch = (config = {}) => {
    fetchData(config);
  };

  return { data, loading, error, triggerFetch };
};

export default useFetchApi;
