import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import RouteRenderer from '../../../core/routes/routeRenderer';
import ScrollToTop from '../../../core/routes/scrollToTop';

const RouterComponent = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <ScrollToTop />
        <RouteRenderer />
      </BrowserRouter>
    </React.Fragment>
  );
};

export default RouterComponent;
