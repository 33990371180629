/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import useAppTabs from '../../../hooks/useAppTabs.hook';
import { useSearchParams } from 'react-router-dom';
import useFetchApi from '../../../hooks/useFetchApi.hook';
import { GameApiService } from '../../../services/api/player/game.api.services';
import useAuthStore from '../../../stores/useAuthStore.store';
// import useBrandStore from '../../../stores/useBrandStore.store';
import CardGame from '../../atoms/CardGame';
// import GameProviderComponent from '../../organisms/GameProviderComponent';
import GameProviderPageComponent from './components/GameProviderPageComponent';
import { LoadingDot } from '../../atoms/Loading';

const GameProviderPage = () => {
  const [urlSearchParams /* , setUrlSearchParams */] = useSearchParams();
  const { appTabs } = useAppTabs();
  // const { brandId } = useBrandStore((state) => ({ brandId: state.brandId }));
  const { token, isAuthenticated } = useAuthStore((state) => ({
    token: state.token,
    isAuthenticated: state.isAuthenticated,
  }));

  const { QUERY_NAME } = appTabs.GAME_PROVIDER;
  const gpIdQuery = urlSearchParams.get(QUERY_NAME);

  const [gameList, setGameList] = useState([]);

  const {
    data: gamesData,
    loading: gamesLoading,
    // error: gamesError,
  } = useFetchApi(GameApiService.GetGameList2, {
    shouldFetch: true,
    initialConfig: {
      headers: {
        Authorization: token,
      },
      data: {
        gpId: [gpIdQuery],
        limit: 1000,
      },
    },
    customCondition: () => token && isAuthenticated && gpIdQuery,
    dependencies: [token, isAuthenticated, urlSearchParams],
  });

  useEffect(() => {
    if (gamesData) {
      setGameList(gamesData?.data?.body);
      console.log('gamesData : ', gamesData);
    }
  }, [gamesData]);

  return (
    <React.Fragment>
      <GameProviderPageComponent />
      {gamesLoading ? (
        <LoadingDot />
      ) : (
        <React.Fragment>
          <div className="grid grid-cols-3 gap-2">
            {gameList?.map((game, index) => (
              // <div className="h-32" key={game.id}>
              <CardGame key={index} game={game} />
              // </div>
            ))}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default GameProviderPage;
