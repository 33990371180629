/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import WalletDropDown from '../../organisms/Header/components/WalletDropDown';
import CopyContent from '../../atoms/CopyContent';

const WalletDepositCrypto = () => {
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState(null);

  useEffect(() => {
    if (selectedWallet) {
      setSelectedNetwork(
        selectedWallet?.networkList && selectedWallet?.networkList.length ? selectedWallet?.networkList?.[0] : []
      );
    }
  }, [selectedWallet]);

  const handleSelectedNetwork = (network) => {
    if (network) {
      setSelectedNetwork(network);
    }
  };

  return (
    <React.Fragment>
      <div className="space-y-2 text-text-color-secondary text-sm">
        <WalletDropDown selectedWallet={selectedWallet} setSelectedWallet={setSelectedWallet} currencyType="crypto" />

        {selectedWallet?.networkList && selectedWallet?.networkList.length ? (
          <React.Fragment>
            <div className="flex h-8 overflow-auto text-center items-center">
              {selectedWallet.networkList.map((network, index) => (
                <React.Fragment key={index}>
                  <div
                    className={`
                      flex bg-body-color-secondary rounded-xl px-4 h-full text-center items-center cursor-pointer
                      ${
                        selectedNetwork?.network === network?.network
                          ? 'bg-navbar-active-color-primary text-text-color-primary'
                          : 'bg-body-color-secondary'
                      }
                    `}
                    onClick={() => {
                      handleSelectedNetwork(network);
                    }}
                  >
                    <span>{network?.network}</span>
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className="flex w-full h-8 rounded-xl bg-body-color-secondary justify-between items-center px-4">
              <div className="w-full overflow-hidden truncate">
                <span>{selectedNetwork?.address}</span>
              </div>
              <CopyContent text={selectedNetwork?.address}>
                <ContentCopyIcon />
              </CopyContent>
            </div>

            <div className="flex w-full justify-center">
              <img src={selectedNetwork?.qrCode} alt="" loading="lazy" />
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default WalletDepositCrypto;
