/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useApiRequest from '../../../hooks/useApiRequest.hook';
import useAuthStore from '../../../stores/useAuthStore.store';
import { useBackButton } from '@tma.js/sdk-react';
import ModalNotifComponent from '../../atoms/ModalNotifComponent';
import ButtonComponent from '../../atoms/ButtonComponent';

const PlayPage = () => {
  const { data: dataGame, loading: loadingGame, post: postGame } = useApiRequest('/getGameUrl');
  const { data: dataDemoGame, loading: loadingDemoGame, post: postDemoGame } = useApiRequest('/demo-get-game-url');
  const { token, isAuthenticated } = useAuthStore();

  const [gameUrl, setGameUrl] = useState(null);
  const [openOutsideGameUrl, setOpenOutsideGameUrl] = useState(null);
  const [openModalGameUrl, setOpenModalGameUrl] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // const mainButton = useMainButton();
  const backButton = useBackButton();
  // const popup = usePopup();
  // mainButton.show();
  // mainButton.setBackgroundColor('#ffffaa');
  // console.log(mainButton.isVisible);
  backButton.show();

  backButton.on('click', () => {
    navigate(-1);
  });

  // mainButton.on('click', () => {});

  useEffect(() => {
    if (isAuthenticated && token) {
      const gpId = searchParams.get('gpId');
      const gpGameId = searchParams.get('gpGameId');
      const demo = searchParams.get('demo');

      if (demo === 'false' && gpId && gpGameId) {
        getGameUrl(gpId, gpGameId);
      } else {
        if (gpId && gpGameId) {
          getDemoGameUrl(gpId, gpGameId);
        }
      }
    }
  }, [isAuthenticated, token, searchParams]);

  useEffect(() => {
    if (dataGame) {
      if (['HKB', 'AP'].includes(searchParams.get('gpId'))) {
        if (dataGame?.type === 'htmlContent') {
          const blob = new Blob([dataGame?.gameUrl?.mobile], { type: 'text/html' });
          console.log('blob : ', blob);
          const url = URL.createObjectURL(blob);
          console.log('url : ', url);
          setOpenModalGameUrl(true);
          setOpenOutsideGameUrl(url);
        } else {
          setOpenModalGameUrl(true);
          setOpenOutsideGameUrl(dataGame?.gameUrl?.mobile);
        }

        // popup.open({
        //   title: 'Open Game',
        //   message: 'Are you sure want to open the game outside telegram?',
        //   buttons: [
        //     { id: '#yes', text: 'Yes', type: 'default' },
        //     { id: '#no', text: 'No', type: 'default' },
        //   ],
        // });
      } else {
        console.log('dataGame?.gameUrl?.mobile : ', dataGame?.gameUrl?.mobile);
        setGameUrl(dataGame?.gameUrl?.mobile);
      }
      // window.location.replace(dataGame?.gameUrl?.mobile);
      // window.open(dataGame?.gameUrl?.mobile);
    }
  }, [dataGame]);

  useEffect(() => {
    if (dataDemoGame) {
      if (['HKB', 'AP'].includes(searchParams.get('gpId'))) {
        setOpenModalGameUrl(true);
        setOpenOutsideGameUrl(dataGame?.gameUrl?.mobile);
      } else {
        setGameUrl(dataDemoGame?.gameUrl?.mobile);
      }
    }
  }, [dataDemoGame]);

  const getGameUrl = (gpId, gpGameId) => {
    postGame({
      data: {
        gpId: gpId,
        gpGameId: gpGameId,
      },
      options: {
        headers: {
          Authorization: token,
        },
      },
    });
  };

  const getDemoGameUrl = (gpId, gpGameId) => {
    postDemoGame({
      data: {
        gpId: gpId,
        gpGameId: gpGameId,
      },
      options: {
        headers: {
          Authorization: token,
        },
      },
    });
  };

  const onCloseHandler = () => {
    navigate(-1);
    setOpenModalGameUrl(false);
  };

  const onOpenHandler = () => {
    window.open(openOutsideGameUrl);
  };

  // Loading state
  if (loadingGame || loadingDemoGame) {
    return (
      <React.Fragment>
        {/* <div className="absolute left-0 top-12 bottom-12 w-full bg-black">
          <div className="flex h-full justify-center items-center">
            <div className="relative w-24 h-24 animate-spin rounded-full bg-gradient-to-r from-purple-400 via-blue-500 to-red-400 ">
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 rounded-full border-2 border-white"></div>
            </div>
          </div>
        </div> */}

        <div className="fixed inset-0 bg-black">
          <div className="flex h-full justify-center items-center">
            <div className="relative w-24 h-24 animate-spin rounded-full bg-gradient-to-r from-purple-400 via-blue-500 to-red-400 ">
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 rounded-full border-2 border-white"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  // Loaded state
  return (
    <React.Fragment>
      <ModalNotifComponent isOpen={openModalGameUrl} onClose={onCloseHandler} title={'External adventure! 🚀'}>
        <div className="text-justify space-y-4">
          <div>
            For the best experience, you'll need to open this game in a browser. Tap "Play" to continue and leave
            Telegram.
          </div>
          <div>To return, simply use your device's app switcher or relaunch the Telegram app.</div>
          <ButtonComponent buttonName="Play" onClick={onOpenHandler} />
        </div>
      </ModalNotifComponent>
      <div className="fixed inset-0 bg-black">
        {gameUrl && <iframe id="gameFrame" title="Game Frame" src={gameUrl} className="w-full h-full"></iframe>}
      </div>
      {/* <div className="fixed inset-0 bg-black">
        {gameUrl && <iframe id="gameFrame" title="Game Frame" src={gameUrl} className="w-full h-full"></iframe>}
      </div> */}
      {/* <div className="absolute left-0 top-12 bottom-12 w-full bg-black">
        {gameUrl && <iframe id="gameFrame" title="Game Frame" src={gameUrl} className="w-full h-full"></iframe>}
      </div> */}
    </React.Fragment>
  );
};

export default PlayPage;
