/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import DropDown from '../../../../atoms/DropDown';
import walletUtils from '../../../../../utils/wallet.utils';
import useAuthStore from '../../../../../stores/useAuthStore.store';

const WalletDropDown = ({ selectedWallet, setSelectedWallet, currencyType = 'all', walletIndex = 0 }) => {
  const { walletBalance } = useAuthStore();

  useEffect(() => {
    let filterWallets;
    if (currencyType === 'all') {
      // If currencyType is 'all', use all wallet balances without filtering
      filterWallets = Array.from(walletBalance);
    } else {
      // Otherwise, filter based on the currencyType
      filterWallets = Array.from(walletBalance).filter(([, walletData]) => walletData.currencyType === currencyType);
    }

    if (filterWallets.length > 0 && setSelectedWallet) {
      // Ensure the walletIndex is within the bounds of the array
      const safeIndex = Math.min(walletIndex, filterWallets.length - 1);
      setSelectedWallet(filterWallets[safeIndex][1]);
    }
  }, [walletBalance]);

  const handleWalletSelect = (walletData) => {
    if (setSelectedWallet) {
      setSelectedWallet(walletData);
    }
  };
  return (
    <React.Fragment>
      <DropDown
        mainMenu={
          <div className="flex w-full h-full items-center space-x-4 text-text-color-secondary text-sm">
            <div className="w-6 h-6 items-center">
              <img src={selectedWallet?.currencyLogo} alt="" className="w-full h-full" loading="lazy" />
            </div>
            <p>{selectedWallet?.currency}</p>
            <p className="flex w-full justify-end">{walletUtils.formatCurrency(selectedWallet?.totalBalance)}</p>
          </div>
        }
        dropdownMenu={
          <>
            {[...walletBalance.entries()]
              .filter(([, walletData]) => ['all'].includes(currencyType) || walletData.currencyType === currencyType)
              .map(([key, walletData], index) => (
                <div
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    handleWalletSelect(walletData);
                  }}
                  className="block px-2 hover:bg-gray-200 cursor-pointer text-text-color-secondary text-sm"
                >
                  <div className="flex w-full h-8 items-center space-x-4">
                    <div className="w-6 h-6 items-center">
                      <img src={walletData?.currencyLogo} alt="" className="w-full h-full" loading="lazy" />
                    </div>
                    <p>{walletData?.currency}</p>
                    <p className="flex w-full justify-end">{walletUtils.formatCurrency(walletData?.totalBalance)}</p>
                  </div>
                </div>
              ))}
          </>
        }
      />
    </React.Fragment>
  );
};

export default WalletDropDown;
