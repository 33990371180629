import React from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const ModalNotifComponent = ({ isOpen, onClose, title, children }) => {
  const onCloseHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <React.Fragment>
      <div
        className="fixed inset-0 px-4 w-screen h-screen bg-black/50 bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto"
        onClick={onCloseHandler}
      >
        <div
          className="w-full bg-slate-700 p-2 rounded-xl shadow-lg relative z-100 space-y-4"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-between items-center text-text-color-primary">
            <p className="text-base font-semibold">{title}</p>
            <CloseOutlinedIcon className="cursor-pointer" onClick={onCloseHandler} />
          </div>
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ModalNotifComponent;
