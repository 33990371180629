/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import useApiRequest from '../../../hooks/useApiRequest.hook';
import ButtonComponent from '../../atoms/ButtonComponent';
import useAuthStore from '../../../stores/useAuthStore.store';
import walletUtils from '../../../utils/wallet.utils';
import useNotification from '../../../hooks/useNotification.hook';
import ModalComponent from '../../atoms/ModalComponent';
import CurrencyInput from 'react-currency-input-field';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import userAppStore from '../../../stores/useAppStore.store';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const initSwapDetail = {
  minSwap: 0,
  maxSwap: 0,
  fee: 0,
  receivedAmount: 0,
  amountRate: 1,
  rate: 0,
};

const Swap = () => {
  const { data: dataFee, loading: loadingFee, error: errorFee, post: postFee } = useApiRequest('/fee');
  const { data: dataSwap, loading: loadingSwap, error: errorSwap, post: postSwap } = useApiRequest('/swap');
  const { notifySuccess, notifyError } = useNotification();

  const { token, isAuthenticated } = useAuthStore();

  const [walletFrom, setWalletFrom] = useState(null);
  const [walletTo, setWalletTo] = useState(null);
  const [swapAmount, setSwapAmount] = useState('0');
  const [swapDetail, setSwapDetail] = useState(initSwapDetail);
  const [error, setError] = useState(null);

  useEffect(() => {
    let timeoutId;

    const delayedFunction = () => {
      if (token && isAuthenticated && walletFrom && walletTo) {
        if (walletFrom?.currency !== walletTo?.currency) {
          setError(null);
          getSwapFee();
        } else {
          setSwapDetail(initSwapDetail);
          setError('please select different currency');
        }
      }
    };

    timeoutId = setTimeout(delayedFunction, 250);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [token, isAuthenticated, walletFrom, walletTo, swapAmount]);

  useEffect(() => {
    if (dataFee) {
      const payload = dataFee?.data;
      setSwapDetail((prev) => {
        return {
          ...prev,
          fee: Number(payload?.fee) ?? 0,
          minSwap: Number(payload?.minTransfer) ?? 0,
          maxSwap: Number(payload?.maxTransfer) ?? 0,
          receivedAmount: Number(payload?.swapBalance) ?? 0,
          rate: Number(payload?.rate) ?? 0,
        };
      });
    }
  }, [dataFee]);

  useEffect(() => {
    if (errorFee) {
      console.log('errorFee : ', errorFee);
      notifyError(errorFee);
    }
  }, [errorFee]);

  useEffect(() => {
    if (errorSwap) {
      console.log('errorFee : ', errorSwap);
      notifyError(errorSwap);
    }
  }, [errorSwap]);

  useEffect(() => {
    if (dataSwap) {
      if (dataSwap?.error === 0) {
        notifySuccess('Swap success');
        setSwapAmount('0');
      } else {
        notifyError('Something wrong');
      }
    }
  }, [dataSwap]);

  const getSwapFee = () => {
    const amount = Number(swapAmount);

    postFee({
      options: {
        headers: {
          Authorization: token,
        },
      },
      data: {
        type: 'swapFee',
        amount: amount,
        fromCurrency: walletFrom?.currency,
        toCurrency: walletTo?.currency,
      },
    });
  };

  const swapBalance = () => {
    const amount = Number(swapAmount);
    setError(null);

    if (walletFrom?.currency === walletTo?.currency) {
      return setError('please select different currency');
    }
    if (amount <= 0) {
      return setError('please input swap amount more than 0');
    }

    if (amount > walletFrom?.totalBalance) {
      return setError('please input swap amount less than seleted current balance');
    }

    if (swapDetail?.minSwap < amount && swapDetail?.maxSwap > amount) {
      return setError(
        `please input swap amount more than fee ${walletUtils.formatCurrency(swapDetail?.minSwap)} ${
          walletFrom?.currency
        } - ${walletUtils.formatCurrency(swapDetail?.maxSwap)} ${walletFrom?.currency}`
      );
    }
    if (swapDetail?.fee >= amount) {
      return setError('please input swap amount more than fee');
    }

    postSwap({
      options: {
        headers: {
          Authorization: token,
        },
      },
      data: {
        amount: amount,
        fromCurrency: walletFrom?.currency,
        toCurrency: walletTo?.currency,
      },
    });
  };

  const onChangeSwapAmount = (value) => {
    setSwapAmount(value);
  };

  const onChangeSwapCurrency = () => {
    setWalletFrom(walletTo);
    setWalletTo(walletFrom);
    setSwapAmount('0');
  };

  return (
    <React.Fragment>
      <div className="text-text-color-secondary text-sm">
        <p className="text-right text-xs leading-none mt-4 mb-1">
          balance {walletUtils.formatCurrency(walletFrom?.totalBalance)} {walletFrom?.currency}
        </p>
        <WalletSwapInput
          selectedWallet={walletFrom}
          setSelectedWallet={setWalletFrom}
          onChange={onChangeSwapAmount}
          value={swapAmount}
          maxWallet={true}
          title={'From Currency'}
        />
        <p className="flex text-xs justify-between mt-1">
          <span>
            min {swapDetail?.minSwap ?? 0} {walletFrom?.currency}
          </span>
          <span>
            max {swapDetail?.maxSwap === 0 ? 'unlimited' : swapDetail?.maxTransfer ?? 0} {walletFrom?.currency}
          </span>
        </p>

        <div className="flex w-full items-center justify-center my-4">
          <SwapVertIcon onClick={onChangeSwapCurrency} />
        </div>

        <WalletSwapInput
          selectedWallet={walletTo}
          setSelectedWallet={setWalletTo}
          disabled
          value={swapDetail?.receivedAmount}
          title={'To Currency'}
          walletIndex={1}
        />

        <div className="text-xs mt-4 mb-8">
          <p className="flex justify-between">
            <span>
              {swapDetail?.amountRate} {walletFrom?.currency} = {walletUtils.formatCurrency(swapDetail?.rate)}{' '}
              {walletTo?.currency}
            </span>
            <span>
              fee {walletUtils.formatCurrency(swapDetail?.fee)} {walletFrom?.currency}
            </span>
          </p>
        </div>

        {error && <p className="text-red-600 text-center">{error}</p>}
        <ButtonComponent loading={loadingFee || loadingSwap} buttonName="Swap" onClick={swapBalance} />
      </div>
    </React.Fragment>
  );
};

const WalletSwapInput = ({
  id = 'inputId',
  selectedWallet,
  setSelectedWallet,
  currencyType = 'all',
  walletIndex = 0,
  value,
  maxWallet = false,
  onChange,
  disabled = false,
  modalTitle = 'Title',
}) => {
  const { setCloseModalApp } = userAppStore();
  const { walletBalance } = useAuthStore();

  const [selectWalletModal, setSelectWalletModal] = useState(false);

  useEffect(() => {
    const filterWallets = [...walletBalance.entries()];
    if (!['all'].includes(currencyType)) {
      filterWallets.filter(([, walletData]) => walletData.currencyType === currencyType);
    }
    if (filterWallets.length > 0) {
      if (setSelectedWallet) {
        setSelectedWallet(filterWallets[walletIndex][1]);
      }
    }
  }, [walletBalance]);

  const handleWalletSelect = (walletData) => {
    if (setSelectedWallet) {
      setSelectedWallet(walletData);
    }
  };

  const onChangeHandler = (value, name) => {
    if (onChange) {
      if (value) {
        onChange(value);
      } else {
        onChange(0);
      }
    }
  };

  const onOpenSelectWalletModal = () => {
    setSelectWalletModal(true);
  };

  const onCloseSelectWalletModal = () => {
    setSelectWalletModal(false);
  };

  const onMaxButtonClick = () => {
    if (onChange) {
      onChange(selectedWallet?.totalBalance ?? 0);
    }
  };

  return (
    <React.Fragment>
      <div className="flex w-full h-10 items-center pl-2 pr-4 bg-body-color-secondary rounded-xl">
        <div
          className="flex justify-between text-text-color-primary w-[10rem] h-8 px-2 items-center space-x-2 rounded-xl bg-body-color-tertiary cursor-pointer"
          onClick={onOpenSelectWalletModal}
        >
          <div className="w-6 h-6 items-center">
            <img src={selectedWallet?.currencyLogo} alt="" className="w-full h-full" loading="lazy" />
          </div>
          <div>{selectedWallet?.currency}</div>
          <ArrowDropDownIcon />
        </div>

        {maxWallet && (
          <div
            className="flex ml-1 rounded-xl items-center text-text-color-primary h-8 px-2 bg-body-color-tertiary cursor-pointer"
            onClick={onMaxButtonClick}
          >
            Max
          </div>
        )}

        <CurrencyInput
          id={id}
          name="amount"
          placeholder="0"
          value={value}
          className="w-full h-8 text-sm appearance-none focus:outline-none focus:border-none focus:ring-0 rounded-xl bg-body-color-secondary text-text-color-primary text-right"
          decimalsLimit={6}
          onValueChange={onChangeHandler}
          min={0}
          allowNegativeValue={false}
          disabled={disabled}
        />
        <ModalComponent isOpen={selectWalletModal} onClose={onCloseSelectWalletModal} title={modalTitle}>
          {[...walletBalance.entries()]
            .filter(([, walletData]) => ['all'].includes(currencyType) || walletData.currencyType === currencyType)
            .map(([key, walletData], index) => (
              <div
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  handleWalletSelect(walletData);
                  onCloseSelectWalletModal();
                  setCloseModalApp();
                }}
                className="block px-2 hover:bg-gray-200 cursor-pointer"
              >
                <div className="flex w-full h-8 items-center space-x-4">
                  <div className="w-6 h-6 items-center">
                    <img src={walletData?.currencyLogo} alt="" className="w-full h-full" loading="lazy" />
                  </div>
                  <p>{walletData?.currency}</p>
                  <p className="flex w-full justify-end">{walletUtils.formatCurrency(walletData?.totalBalance)}</p>
                </div>
              </div>
            ))}
        </ModalComponent>
      </div>
    </React.Fragment>
  );
};

export default Swap;
