import React from 'react';

const RewardsPage = () => {
  return (
    <React.Fragment>
      <div className="flex w-full font-bold text-xl justify-center">Rewards</div>
      <div className="flex w-full mt-20 justify-center text-text-color-secondary">Coming Soon</div>
    </React.Fragment>
  );
};

export default RewardsPage;
