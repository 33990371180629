/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import WalletDepositCrypto from '../../molecules/WalletDepositCrypto';
import WalletDepositFiat from '../../molecules/WalletDepositFiat';
import NavigationTab from '../NavigationTab';
import useAppTabs from '../../../hooks/useAppTabs.hook';
import { useSearchParams } from 'react-router-dom';

const WalletDeposit = () => {
  const { appTabs } = useAppTabs();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(null);

  const { QUERY_NAME, DEFAULT_QUERY_URL, ROUTES } = appTabs.WALLET.ROUTES.DEPOSIT.CURRENCY_TYPE_TAB;

  useEffect(() => {
    const existQuery = urlSearchParams.get(QUERY_NAME);
    if (!existQuery) {
      setUrlSearchParams((prev) => {
        const newUrlParams = {};
        prev.forEach((value, key) => {
          Object.assign(newUrlParams, {
            [key]: value,
          });
        });

        Object.assign(newUrlParams, {
          [QUERY_NAME]: DEFAULT_QUERY_URL,
        });

        return newUrlParams;
      });
    }
  }, []);

  useEffect(() => {
    setSelectedTab(urlSearchParams.get(QUERY_NAME));
  }, [urlSearchParams]);

  return (
    <React.Fragment>
      <NavigationTab queryName={QUERY_NAME} childTab={true} tabs={ROUTES} />
      <div className="w-full h-full">
        {selectedTab === ROUTES.CRYPTO.QUERY_URL && <WalletDepositCrypto />}
        {selectedTab === ROUTES.FIAT.QUERY_URL && <WalletDepositFiat />}
      </div>
    </React.Fragment>
  );
};

export default WalletDeposit;
