/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import useApiRequest from '../../../hooks/useApiRequest.hook';
import useAuthStore from '../../../stores/useAuthStore.store';
import formatDateUtils from '../../../utils/formatDate';
import walletUtils from '../../../utils/wallet.utils';
import { LoadingDot } from '../../atoms/Loading';

const HistorySwap = () => {
  const { data, loading, /* error, */ post } = useApiRequest('/getSwapHistory');
  const { token, isAuthenticated } = useAuthStore();

  const [swapHistoryData, setSwapHistoryData] = useState([]);

  useEffect(() => {
    if (data) {
      setSwapHistoryData((prev) => {
        return [...prev, ...(data?.result ?? [])];
      });
    }
  }, [data]);

  useEffect(() => {
    if (token && isAuthenticated) {
      getSwapHistory();
    }
  }, [token, isAuthenticated]);

  const getSwapHistory = () => {
    post({
      options: {
        headers: {
          Authorization: token,
        },
      },
    });
  };

  return (
    <React.Fragment>
      <div className="text-xs">
        <table className="w-full">
          <thead>
            <tr className="flex">
              <th className="flex-1 px-4 p-1 w-1/4 word-wrap break-word white-space-normal">Datetime</th>
              <th className="flex-1 px-4 p-1 w-1/4 word-wrap break-word white-space-normal">Swap From</th>
              <th className="flex-1 px-4 p-1 w-1/4 word-wrap break-word white-space-normal">Swap To</th>
            </tr>
          </thead>
          <tbody className="w-full">
            {!swapHistoryData.length && loading && <LoadingDot />}
            {swapHistoryData.map((item, index) => (
              <React.Fragment key={index}>
                <tr className="flex border-t items-center">
                  <td className="p-1 w-1/4 text-start flex-1 word-wrap break-word white-space-normal">
                    <p>{formatDateUtils.formatDate(item?.transactionDate)}</p>
                    <p>{formatDateUtils.formatTimeZone(item?.transactionDate)}</p>
                  </td>
                  <td className="p-1 w-1/4 text-end flex-1 word-wrap break-word white-space-normal">
                    <p>
                      {walletUtils.formatCurrency(item?.totalAmountFromCurrency)}&nbsp;
                      <span>{item?.currency}</span>
                    </p>
                  </td>
                  <td className="p-1 w-1/4 text-end flex-1 word-wrap break-word white-space-normal">
                    <p>
                      {walletUtils.formatCurrency(item?.totalAmountToCurrencyAfterFee)}&nbsp;
                      <span>{item?.targetCurrency}</span>
                    </p>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default HistorySwap;
