const walletListToMap = (balance) => {
  const balanceMap = new Map();

  for (const item in balance) {
    balanceMap.set(item, balance[item]);
  }

  return balanceMap;
};

const updateWallet = (existWallet, newWallet) => {
  if (existWallet && newWallet) {
    const existBalance = new Map(existWallet);
    let newBalance = newWallet;

    for (const item in newBalance) {
      const prevData = existBalance.get(item);

      if (prevData && typeof prevData === 'object') {
        existBalance.set(item, { ...prevData, totalBalance: newBalance[item] });
      }
    }

    return existBalance;
  }
};

// const formatCurrency = (num) => {
//   // Round to a maximum of 6 decimal places
//   num = Math.round(num * 1000000) / 1000000;

//   // Split the number into its integer and decimal parts
//   let [intPart, decPart] = num.toString().split('.');

//   // Add a period (.) every 3 digits in the integer part
//   intPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

//   // Return the formatted number, or just the integer part if no decimal
//   return decPart ? `${intPart}.${decPart}` : intPart;
// };

const formatCurrency = (num, maxDecimalPlaces = 5) => {
  // Round to the specified number of decimal places
  const factor = Math.pow(10, maxDecimalPlaces);
  num = Math.round(num * factor) / factor;

  // Split the number into its integer and decimal parts
  let [intPart, decPart] = num.toString().split('.');

  // Add a period (.) every 3 digits in the integer part
  intPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // If there is a decimal part, truncate it to the max number of decimal places
  if (decPart) {
    decPart = decPart.substring(0, maxDecimalPlaces);
  }

  // Return the formatted number, or just the integer part if no decimal
  return decPart ? `${intPart}.${decPart}` : intPart;
};

const walletUtils = { walletListToMap, updateWallet, formatCurrency };

export default walletUtils;
