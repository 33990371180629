import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useAuthStore from '../../../../../stores/useAuthStore.store';
import useSidebarStore from '../../../../../stores/useSidebarStore.store';

const ProfileIcon = () => {
  const { user } = useAuthStore((state) => ({
    user: state.user,
  }));
  const { setOpenSidebar } = useSidebarStore((state) => ({
    setOpenSidebar: state.setOpenSidebar,
  }));

  return (
    <React.Fragment>
      <div
        className="flex items-center justify-center bg-body-color-tertiary rounded-full w-10 h-10 cursor-pointer aspect-square"
        onClick={setOpenSidebar}
      >
        {user?.telegram?.profilePicture ? (
          <img
            src={user?.telegram?.profilePicture}
            className="w-full h-full object-cover rounded-full"
            alt=""
            loading="lazy"
          />
        ) : (
          <AccountCircleIcon className="text-text-color-secondary" />
        )}
      </div>
    </React.Fragment>
  );
};

export default ProfileIcon;
