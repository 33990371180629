import { useState } from 'react';
import api from '../services/api/api';

function useApiRequest(url) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const makeRequest = async ({ method, data = {}, options = {} }) => {
    setLoading(true);
    setError(null);
    setData(null);
    try {
      const response = await api({ method, url, data, ...options });
      setData(response);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    error,
    loading,
    get: ({ options }) => makeRequest({ method: 'get', options }),
    post: ({ data = {}, options }) => makeRequest({ method: 'post', data, options }),
    put: ({ data = {}, options }) => makeRequest({ method: 'put', data, options }),
    // Add similar wrappers for DELETE, PATCH, etc. if needed
  };
}

export default useApiRequest;
