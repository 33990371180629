/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
// import useAuthStore from '../../../stores/useAuthStore.store';
import Banner from "../../organisms/Banner";
import GameSectionComponent from "../../organisms/GameSectionComponent";
import GameSearchComponent from "../../organisms/GameSearchComponent";
import { useBackButton } from "@tma.js/sdk-react";
// import GameProviderComponent from '../../organisms/GameProviderComponent';

const HomePage = () => {
  // const { token, isAuthenticated } = useAuthStore();
  const backButton = useBackButton();
  backButton.hide();

  return (
    <React.Fragment>
      <div className="w-full h-full">
        <Banner />
        <GameSearchComponent />
        <GameSectionComponent
          navigation={true}
          sectionTitle={"Slot"}
          gameCategory="sl"
        />
        <GameSectionComponent
          navigation={true}
          sectionTitle={"Live"}
          gameCategory="lc"
        />
        <GameSectionComponent
          navigation={true}
          sectionTitle={"Card"}
          gameCategory="cg"
        />
        <GameSectionComponent
          navigation={true}
          sectionTitle={"Lottery"}
          gameCategory="lt"
        />
        {/* <GameProviderComponent /> */}
      </div>
    </React.Fragment>
  );
};

export default HomePage;
