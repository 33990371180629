import React from 'react';
import { useSearchParams } from 'react-router-dom';

const NavigationTab = ({ tabs, childTab = false, queryName, tabType = 'flex' }) => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();

  const onClickTab = (queryValue) => {
    if (!childTab) {
      setUrlSearchParams({ [queryName]: queryValue });
    } else {
      setUrlSearchParams((prev) => {
        const newUrlParams = {};
        prev.forEach((value, key) => {
          Object.assign(newUrlParams, {
            [key]: value,
          });
        });

        Object.assign(newUrlParams, {
          [queryName]: queryValue,
        });

        return newUrlParams;
      });
    }
  };
  return (
    <React.Fragment>
      <div className="w-full h-full text-sm mb-2">
        {tabType === 'flex' && (
          <div className="overflow-x-auto rounded-xl">
            <div className="flex items-center whitespace-nowrap space-x-2">
              {Object.keys(tabs).map((key, index) => (
                <React.Fragment key={index}>
                  <div
                    className={`flex-1 flex px-4 h-8 items-center justify-center cursor-pointer rounded-xl
                      ${
                        queryName && tabs[key].QUERY_URL === urlSearchParams.get(queryName)
                          ? 'bg-navbar-active-color-primary'
                          : 'bg-body-color-secondary'
                      }
                    `}
                    onClick={() => onClickTab(tabs[key].QUERY_URL)}
                  >
                    <span>{tabs[key].TITLE}</span>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default NavigationTab;
