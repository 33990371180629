const { create } = require('zustand');

const useEditProfileStore = create((set) => ({
  openEditProfileModal: false,
  setOpenEditProfileModal: () => {
    set({ openEditProfileModal: true });
  },
  setCloseEditProfileModal: () => {
    set({ openEditProfileModal: false });
  },
}));

export default useEditProfileStore;
