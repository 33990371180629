/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import useApiRequest from '../../../hooks/useApiRequest.hook';
import useAuthStore from '../../../stores/useAuthStore.store';
import formatDateUtils from '../../../utils/formatDate';
import walletUtils from '../../../utils/wallet.utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ModalNotifComponent from '../../atoms/ModalNotifComponent';
import { LoadingDot } from '../../atoms/Loading';

const HistoryDepoWd = () => {
  const { data, loading, post } = useApiRequest('/reports');

  const { token, isAuthenticated } = useAuthStore();

  const [depowdReportData, setDepowdReportData] = useState([]);
  const [depowdDetailModal, setDepowdDetailModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (token && isAuthenticated) {
      getDepoWdReport();
    }
  }, [token, isAuthenticated]);

  useEffect(() => {
    console.log('data : ', data);
    if (data) {
      setDepowdReportData((prev) => {
        return [...prev, ...data?.data];
      });
    }
  }, [data]);

  const getDepoWdReport = () => {
    post({
      options: {
        headers: {
          Authorization: token,
        },
      },
      data: {
        systemType: 'depowd',
        type: 'report',
        currency: 'ALL',
        sort: 'NEWEST',
        transactionType: ['DEPOSIT', 'WITHDRAW'],
      },
    });
  };

  const onCloseDepowdDetail = () => {
    setDepowdDetailModal(false);
  };

  return (
    <React.Fragment>
      <div className="text-xs">
        <table className="w-full">
          <thead>
            <tr className="flex">
              <th className="flex-1 px-4 p-1 w-1/4 word-wrap break-word white-space-normal">Transaction</th>
              <th className="flex-1 px-4 p-1 w-1/4 word-wrap break-word white-space-normal">Amount</th>
              <th className="flex-1 px-4 p-1 w-1/4 word-wrap break-word white-space-normal">Status</th>
            </tr>
          </thead>
          <tbody className="w-full">
            {!depowdReportData.length && loading && <LoadingDot />}
            {depowdReportData.map((item, index) => (
              <React.Fragment key={index}>
                <tr className="flex border-t items-center">
                  <td className="p-1 w-1/4 text-start flex-1 word-wrap break-word white-space-normal">
                    <p>{item?.transactionType === 'DEPOSIT' && 'Deposit'}</p>
                    <p>{item?.transactionType === 'WITHDRAW' && 'Withdraw'}</p>
                    <p>{formatDateUtils.formatDateTime(item?.createdAt)}</p>
                  </td>
                  <td className="p-1 w-1/4 text-end flex-1 word-wrap break-word white-space-normal">
                    <p>
                      {walletUtils.formatCurrency(item?.amount)}&nbsp;
                      <span>{item?.currency}</span>
                    </p>
                  </td>
                  <td className="p-1 w-1/4 text-end flex-1 word-wrap break-word white-space-normal">
                    {item?.transactionStatus.toLowerCase() === 'pending' && <p className="text-yellow-600">Pending</p>}
                    {item?.transactionStatus.toLowerCase() === 'success' && <p className="text-green-600">Success</p>}
                    {item?.transactionStatus.toLowerCase() === 'failed' && <p className="text-red-600">Failed</p>}
                    {item?.transactionStatus.toLowerCase() === 'adjustment' && (
                      <p className="text-text-color-primary">Adjustment</p>
                    )}
                    <InfoOutlinedIcon
                      className="cursor-pointer"
                      onClick={() => {
                        setDepowdDetailModal(true);
                        setSelectedItem(item);
                      }}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {depowdDetailModal && (
        <React.Fragment>
          <ModalNotifComponent isOpen={true} onClose={onCloseDepowdDetail} title={'Transaction Detail'}>
            <div className="text-sm space-y-1">
              <Detail
                name={'Status'}
                value={
                  <React.Fragment>
                    {selectedItem?.transactionStatus.toLowerCase() === 'pending' && (
                      <p className="text-yellow-600">Pending</p>
                    )}
                    {selectedItem?.transactionStatus.toLowerCase() === 'success' && (
                      <p className="text-green-600">Success</p>
                    )}
                    {selectedItem?.transactionStatus.toLowerCase() === 'failed' && (
                      <p className="text-red-600">Failed</p>
                    )}
                    {selectedItem?.transactionStatus.toLowerCase() === 'adustment' && (
                      <p className="text-text-color-primary">Adjustment</p>
                    )}
                  </React.Fragment>
                }
              />
              <Detail name={'Transaction Date'} value={formatDateUtils.formatDateTime(selectedItem?.createdAt)} />
              <Detail name={'Transaction ID'} value={selectedItem?.id} />
              {selectedItem?.transactionType.toLowerCase() === 'deposit' && (
                <React.Fragment>
                  {/* {selectedItem?.paymentMethod?.toLowerCase() === 'crypto' && (
                    <Detail name={'Transfer From'} value={selectedItem?.id} />
                  )} */}
                </React.Fragment>
              )}
              <Detail
                name={'Method'}
                value={[selectedItem?.paymentMethod ?? '', selectedItem?.network ?? ''].join(' - ')}
              />
              {selectedItem?.transactionType.toLowerCase() === 'withdraw' && (
                <React.Fragment>
                  {selectedItem?.paymentMethod?.toLowerCase() === 'crypto' && (
                    <Detail name={'Transfer To'} value={selectedItem?.toAddress} />
                  )}
                </React.Fragment>
              )}
              <Detail name={'Currency'} value={selectedItem?.currency} />
              <Detail name={'Amount'} value={selectedItem?.totalAmount} />
              <Detail name={'Fee'} value={selectedItem?.fee} />
              <Detail name={'Amount Received'} value={selectedItem?.amount} />
            </div>
          </ModalNotifComponent>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const Detail = ({ name, value }) => {
  return (
    <div className="grid grid-cols-5">
      <div className="col-span-2">{name ?? '-'}</div>
      <div className="col-span-3 break-all">{value ?? '-'}</div>
    </div>
  );
};

export default HistoryDepoWd;
