/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import 'swiper/css';
import { FreeMode } from 'swiper/modules';

import CardGame from '../../atoms/CardGame';
// import useApiRequest from '../../../hooks/useApiRequest.hook';
// import useBrandStore from '../../../stores/useBrandStore.store';
// import useAuthStore from '../../../stores/useAuthStore.store';
import useGameStore from '../../../stores/useGameStore';
// import useGameStore from '../../../stores/useGameStore';

SwiperCore.use([Navigation]);

const GameSectionComponent = ({
  sectionTitle = 'section',
  navigation = false,
  type = 'category',
  gameCategory = 'all',
}) => {
  // const { data, loading, error, post } = useApiRequest('/gameNoAuth');

  const { gameByCategoryData /*, isGameCategoryStored, setGameByCategoryData */ } = useGameStore();
  // const { brandId } = useBrandStore();
  // const { token /* , isAuthenticated  */ } = useAuthStore();
  // const { gamesData, setGamesData } = useGameStore();

  const [games, setGames] = useState(gameByCategoryData?.[gameCategory]?.games ?? []);
  const swiperRef = useRef(null);

  // const types = {
  //   category: {
  //     systemType: 'getHomeCategoryGame',
  //     brandId: brandId,
  //     gameCategory: gameCategory,
  //   },
  //   recommended: {
  //     systemType: 'getRecommendedGame',
  //     brandId: brandId,
  //   },
  // };

  useEffect(() => {
    if (gameByCategoryData) {
      console.log('gameByCategoryData : ', gameByCategoryData);
      setGames(gameByCategoryData?.[gameCategory]?.games ?? []);
    }
  }, [gameByCategoryData]);

  // useEffect(() => {
  //   if (isAuthenticated && token && brandId) {
  //     getGames();
  //     // Check if data for the gameCategory is already in the store
  //     // console.log('gamesData[gameCategory] : ', gamesData[gameCategory]);
  //     // if (!gamesData[gameCategory]) {
  //     //   console.log('masuk check');
  //     //   getGames();
  //     // } else {
  //     //   console.log('masuk check 2');
  //     //   setGames(gamesData[gameCategory]);
  //     // }
  //   }
  // }, [isAuthenticated, token, brandId]);

  // useEffect(() => {
  //   if (!['all'].includes(gameCategory)) {
  //     const currentGames = data?.data?.[gameCategory]?.games ?? [];
  //     setGames(currentGames);
  //     // setGamesData(gameCategory, currentGames);
  //   } else {
  //     const currentGames = data?.data?.['sl']?.games ?? [];
  //     setGames(currentGames);
  //     // setGamesData('sl', currentGames);
  //   }
  // }, [data, gameCategory]);

  useEffect(() => {
    const swiperContainer = swiperRef.current?.el; // get the DOM node from the ref

    if (swiperContainer) {
      console.log('swiperContainer : ', swiperContainer);
      swiperContainer.addEventListener('touchstart', () => {
        alert('tekan');
      });
      const lockScroll = () => document.body.classList.add('lock-scroll');
      const unlockScroll = () => document.body.classList.remove('lock-scroll');

      swiperContainer.addEventListener('touchstart', lockScroll);
      swiperContainer.addEventListener('touchend', unlockScroll);
      swiperContainer.addEventListener('touchcancel', unlockScroll);

      return () => {
        swiperContainer.removeEventListener('touchstart', lockScroll);
        swiperContainer.removeEventListener('touchend', unlockScroll);
        swiperContainer.removeEventListener('touchcancel', unlockScroll);
      };
    } else {
      // alert('gk masuk');
    }
  }, []);

  // const getGames = useCallback(() => {
  //   post({
  //     data: types[type],
  //     options: {
  //       headers: {
  //         Authorization: token,
  //       },
  //     },
  //   });
  // }, [post, brandId, token, type, gameCategory]);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  // const onTouchStartSwiper = () => {
  //   console.log('ter touching');
  //   const swiperContainer = swiperRef.current?.el;
  //   console.log('swiperContainer : ', swiperContainer);
  //   if (swiperContainer) {
  //     alert('on touch');
  //     const lockScroll = () => document.body.classList.add('lock-scroll');
  //     swiperContainer.addEventListener('touchstart', lockScroll);
  //   }
  // };
  // const onTouchEndSwiper = () => {
  //   const swiperContainer = swiperRef.current?.el;
  //   if (swiperContainer) {
  //     console.log('ter touching');
  //     const unlockScroll = () => document.body.classList.remove('lock-scroll');
  //     swiperContainer.addEventListener('touchstart', unlockScroll);
  //   }
  // };

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>Error: {error.message}</div>;
  // }

  return (
    <React.Fragment>
      {games && games.length ? (
        <React.Fragment>
          <div className="space-y-4 pb-6">
            <div className="flex justify-between items-center leading-none">
              <div className="text-base font-semibold">{sectionTitle}</div>

              {Boolean(navigation) && (
                <React.Fragment>
                  <div className="space-x-4">
                    <KeyboardArrowLeftIcon onClick={goPrev} />
                    <KeyboardArrowRightIcon onClick={goNext} />
                  </div>
                </React.Fragment>
              )}
            </div>

            <Swiper
              id="swiper-id"
              ref={swiperRef}
              slidesPerView={'2.5'}
              spaceBetween={16}
              freeMode={true}
              modules={[FreeMode]}
              // touchMoveStopPropagation={true}
              // preventInteractionOnTransition={true}
              className="mySwiper"
              // onTouchStart={(swiper, event) => {
              //   if (swiper.isHorizontal) {
              //     event.preventDefault();
              //   }
              // }}
              // onTouchStart={(e) => {}}
              // onTouchEnd={onTouchEndSwiper}
              // onTouchCancel={onTouchEndSwiper}
            >
              {games.map((game, index) => (
                <SwiperSlide key={index}>
                  {/* <div className="h-32"> */}
                  <CardGame game={game} />
                  {/* </div> */}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default GameSectionComponent;
