import React from 'react';
import useAuthStore from '../../../stores/useAuthStore.store';
import ButtonComponent from '../../atoms/ButtonComponent';
import useEditProfileStore from './stores/useEditProfileStore.store';
import EditProfile from './components/EditProfile';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const AccountPage = () => {
  const { user } = useAuthStore((state) => ({
    user: state.user,
  }));
  const { setOpenEditProfileModal } = useEditProfileStore((state) => ({
    setOpenEditProfileModal: state.setOpenEditProfileModal,
  }));

  return (
    <React.Fragment>
      <p className="text-xl font-semibold">My Profile</p>
      <div className="flex mt-3">
        <div className="mr-2">
          <AccountCircleIcon
            style={{
              width: '60px',
              height: '60px',
            }}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <p className="flex items-end flex-1 text-lg font-medium leading-none">{user?.username}</p>
          <p className="flex items-start flex-1 text-sm text-navbar-active-color-primary leading-none">
            {user?.memberLevel}
          </p>
        </div>
      </div>
      <div className="flex flex-col mb-4 text-xl"></div>
      {/* <ProfileForm title={'Username'} value={user?.username} />
      <ProfileForm title={'Member Level'} value={user?.memberLevel} /> */}
      <ProfileForm title={'Fullname'} value={user?.fullname ?? '-'} />
      <ProfileForm title={'Gender'} value={user?.gender} />
      <ProfileForm title={'Date of birth'} value={user?.birthdate} />
      <ProfileForm title={'Email'} value={user?.email} />
      <ProfileForm title={'Phone Number'} value={user?.phoneNumber} />

      <ProfileForm title={'Country'} value={user?.country} />
      <ProfileForm title={'Street Address'} value={user?.address} />
      <ProfileForm title={'City'} value={user?.city} />
      <ProfileForm title={'State / Province'} value={user?.province} />
      <ProfileForm title={'Zip Code'} value={user?.postalCode} />

      <div className="space-y-2 mt-4">
        {/* <ButtonComponent buttonName="Change password" /> */}
        <ButtonComponent buttonName="Edit profile" onClick={setOpenEditProfileModal} />
      </div>
      <EditProfile />
    </React.Fragment>
  );
};

const ProfileForm = ({ title = 'title', value = '-' }) => {
  return (
    <React.Fragment>
      <div className="grid grid-cols-3 break-words text-sm py-1">
        <div className="col-span-1">{title}</div>
        <div className="col-span-2">{value !== '' ? value : '-'}</div>
      </div>
    </React.Fragment>
  );
};

export default AccountPage;
