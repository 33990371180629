import React from 'react';
import { useLocation } from 'react-router-dom';
import appRoutes from '../../../core/routes/routes';

const Footer = () => {
  const location = useLocation();

  const footerRoutes = [appRoutes.HOME];

  if (!footerRoutes.includes(location.pathname)) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="containerContent flex items-center text-xs text-text-color-secondary justify-center w-full h-14 bg-body-color-secondary text-center pb-12">
        © 2023 DicePlay All rights reserved
      </div>
    </React.Fragment>
  );
};
export default Footer;
