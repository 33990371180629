import React, { useState } from 'react';
import WalletDropDown from '../../organisms/Header/components/WalletDropDown';

const WalletWithdrawFiat = () => {
  const [selectedWallet, setSelectedWallet] = useState(null);
  return (
    <React.Fragment>
      <WalletDropDown selectedWallet={selectedWallet} setSelectedWallet={setSelectedWallet} currencyType="fiat" />
      <div className="flex w-full mt-20 justify-center text-text-color-secondary">Coming Soon</div>
    </React.Fragment>
  );
};

export default WalletWithdrawFiat;
